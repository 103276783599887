
<div class="row" *ngIf="skeleton">
    <div class="col-lg-4">
        <ngx-skeleton-loader 
            count="1" 
            [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>
    <div class="col-lg-4">
        <ngx-skeleton-loader 
            count="1" 
            [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>
    <div class="col-lg-4">
        <ngx-skeleton-loader 
            count="1" 
            [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>
</div>

<div class="kaban-container" cdkDropListGroup>
    <div class="row" cdkDropList (cdkDropListDropped)="onColumnDrop($event)" cdkDropListOrientation="horizontal" [cdkDropListData]="status" cdkDropListGroup>
        <div class="col-lg-4" *ngFor="let s of status">
            <div class="card card-body bg-light" cdkDrag [cdkDragData]="s">
                <h6><i *ngIf="s.icon" [class]="s.icon"></i> {{ s.name }}</h6>
                <div cdkDropList
                    class="item-drop"
                    autoScrollDisabled="true"
                    [cdkDropListData]="s.demands"
                    (cdkDropListDropped)="drop($event, s)">
                    <div cdkDrag [cdkDragData]="d" class="card pointer z-index-2 my-2 item"
                        [style.border]="s.color"
                        [style.background]="d.priority ? '#ff727254' : ''"
                        [routerLink]="'/demands/edit/' + d.id" *ngFor="let d of s.demands">
                        <div class="card-body p-3">
                            <h6 class="text-capitalize mb-0 kaban-title">{{ d.name }}</h6>
                            <p class="text-xs text-secondary mb-0">{{ d.date | date : 'dd/MM/yyyy' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>