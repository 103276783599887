import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { DataService } from 'src/app/services/data.service';
import { OpenaiService } from 'src/app/services/openai.service';


@Component({
  selector: 'app-smartdata-detail',
  templateUrl: './smartdata-detail.component.html',
  styleUrls: ['./smartdata-detail.component.scss']
})
export class SmartdataDetailComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/argon-dashboard-master/assets/animation/animation_ai.json'
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  chartOptionsNPS: any
  chartOptionsNPSSegmento: any
  chartOptionsRechSegmento: any
  chartOptionsTSSegmento: any
  chartOptionsNPSCorrelation: any

  chartOptionsTempoEmpresaFamiliaridade: any
  chartOptionsCargoFamiliaridade: any
  chartOptionsEmpresaFamiliaridade: any

  selectedFiles: File[] = [];
  selectedFile: File | null = null;

  insightAi = ''
  data: any
  insight: any
  title = ''
  loadAi = false
  id = null

  rawData: any

  constructor(
    private service: DataService,
    private route: ActivatedRoute,
    private openaiService: OpenaiService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) this.getById(id)
    })
  }

  getById(id: any) {
    this.service.getById(id).subscribe({
      next: (result: any) => {
        console.log(result)
        this.id = result.id
        // this.insightAi = result.insightAi
        this.rawData = result
        this.data = JSON.parse(result.analysis).data
        this.insight = JSON.parse(result.analysis).insights
        this.generateCharts(this.data, this.insight)
        console.log(JSON.parse(result.analysis))
      },
      error: err => console.log(err)
    })
  }

  onFileSelected(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
    }
  }

  getOpenAIResponseStream(data: any) {
    this.openaiService.getResponseStream(JSON.stringify(data)).subscribe(
      (partialResponse: any) => {
        this.loadAi = false
        this.insightAi += partialResponse; // Acumular a resposta parcial
      },
      error => {
        console.error('Erro:', error);
      },
      () => {
        console.log('Streaming completo');
      }
    );
  }

  onUpload(): void {
    if (this.selectedFile) {
      this.service.uploadFile(this.selectedFile).subscribe({
        next: result => {
          this.data = result.data
          this.id = result.id
          this.insight = result.insights
          console.log(result)
          this.generateCharts(result.data, result.insights)
          this.save(result)
        },
        error: err => console.log(err)
      })
    }
  }

  save(data: any) {
    this.service.save({
      id: this.id,
      name: this.title,
      insight: this.insightAi,
      analysis: data
    }).subscribe({
      next: (result) => console.log(result),
      error: err => console.log(err)
    })
  }

  generateCharts(data: any, insights: any) {

    const npsSeriesData = data.distribuicao_nps
    this.chartOptionsNPS = {
      series: [data.percentages.nps_greater_than_or_equal_75, data.percentages.nps_less_than_75],
      chart: {
        height: 400,
        type: "pie"
      },
      labels: ["Maior que 75%", "Menor que 75%"],
      title: {
        text: "Distribuição NPS",
        align: "left"
      },
      legend: {
        position: "bottom"
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    }
    // this.chartOptionsNPS = {
    //   series: [
    //     {
    //       data: [
    //         {
    //           x: "Total NPS",
    //           y: [npsSeriesData['min'], npsSeriesData['25%'].toFixed(2), npsSeriesData['mean'].toFixed(2), npsSeriesData['75%'].toFixed(2), npsSeriesData['max']]
    //         },
    //       ]
    //     },
    //   ],

    //   chart: {
    //     height: 350,
    //     type: "pie"
    //   },
    //   theme: {
    //     palette: 'palette1' // upto palette10
    //   },
    //   title: {
    //     text: "Destribuição NPS",
    //     align: "left"
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //       barHeight: "50%"
    //     },
    //     boxPlot: {
    //       colors: {
    //         upper: "#e9ecef",
    //         lower: "#f8f9fa"
    //       }
    //     }
    //   },
    //   annotations: {
    //     yaxis: [
    //       {
    //         y: 75,
    //         borderColor: 'red',
    //         label: {
    //           borderColor: 'red',
    //           style: {
    //             color: '#fff',
    //             background: 'red'
    //           },
    //           text: 'Mínimo aceitável'
    //         }
    //       }
    //     ]
    //   }
    // };

    const npsSegmentoSeriesData = data.nps_por_eps.map((item: any) => ({
      x: item['eps'],
      y: item['NPS']
    }));

    console.log(npsSegmentoSeriesData)

    this.chartOptionsNPSSegmento = {
      series: [
        {
          name: "NPS",
          data: npsSegmentoSeriesData
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      theme: {
        palette: 'palette4' // upto palette10
      },
      title: {
        text: "NPS por EPS",
        align: "left"
      },
      plotOptions: {
        bar: {
          columnWidth: "60%"
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val.toFixed(2) + "%";
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return val.toFixed(2) + "%";
          }
        }
      },
      annotations: {
        yaxis: [
          {
            y: 75,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: 'red'
              },
              text: 'Mínimo aceitável (75%)'
            }
          }
        ]
      }
    };

    const rechSeriesData = data.rech_por_segmento.map((item: any) => ({
      x: item['Segmento'],
      y: item['Rech']
    }));
    this.chartOptionsRechSegmento = {
      series: [
        {
          name: "Rechamadas",
          data: rechSeriesData
        }
      ],
      chart: {
        height: 500,
        type: "bar"
      },
      theme: {
        palette: 'palette2' // upto palette10
      },
      title: {
        text: "Rechamada por segmento",
        align: "left"
      },
      plotOptions: {
        bar: {
          columnWidth: "60%"
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val.toFixed(2) + "%";
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return val.toFixed(2) + "%";
          }
        }
      },
      annotations: {
        yaxis: [
          {
            y: 13,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: 'red'
              },
              text: 'Máximo aceitável (13%)'
            }
          }
        ]
      }
    };
    const tempoEmpresaFamvSeriesData = data.tempo_empresa_familiaridade_cultura.map((item: any) => ({
      x: item['temp'],
      y: item['fam']
    }));
    this.chartOptionsTempoEmpresaFamiliaridade = {
      series: [
        {
          name: "Familiaridade Cultura por Tempo de Empresa",
          data: tempoEmpresaFamvSeriesData
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      theme: {
        palette: 'palette3' // upto palette10
      },
      title: {
        text: "Familiaridade Cultura por Tempo de Empresa",
        align: "left",
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return !isNaN(Number(val)) ? val.toFixed(2) : val;
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) : val;
          }
        }
      },
      xaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) : val;
          }
        }
      },
      annotations: {
        xaxis: [
          {
            x: 20,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: 'red'
              },
              text: 'Máximo aceitável (20%)'
            }
          }
        ]
      }
    };
    const empresaFamvSeriesData = data.empresa_familiaridade.map((item: any) => ({
      x: item['empresa'],
      y: item['fam']
    }));
    this.chartOptionsEmpresaFamiliaridade = {
      series: [
        {
          name: "Familiaridade Cultura por Empresa",
          data: empresaFamvSeriesData
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      theme: {
        palette: 'palette3' // upto palette10
      },
      title: {
        text: "Familiaridade Cultura por Empresa",
        align: "left",
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return !isNaN(Number(val)) ? val.toFixed(2) : val;
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) : val;
          }
        }
      },
      xaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) : val;
          }
        }
      }
    };

    const cargoFamvSeriesData = data.cargo_por_familiaridade_cultura.map((item: any) => ({
      x: item['cargo'],
      y: item['fam']
    }));
    this.chartOptionsCargoFamiliaridade = {
      series: [
        {
          name: "Familiaridade Cultura por Cargo",
          data: cargoFamvSeriesData
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      theme: {
        palette: 'palette8' // upto palette10
      },
      title: {
        text: "Familiaridade Cultura por Cargo",
        align: "left",
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return !isNaN(Number(val)) ? val.toFixed(2) : val;
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) : val;
          }
        }
      },
      xaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) : val;
          }
        }
      },
    };

    const tsSeriesData = data.ts_por_segmento.map((item: any) => ({
      x: item['Segmento'],
      y: item['TS']
    }));
    this.chartOptionsTSSegmento = {
      series: [
        {
          name: "Tempo de silencio",
          data: tsSeriesData
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      theme: {
        palette: 'palette3' // upto palette10
      },
      title: {
        text: "Tempo de silencio por segmento",
        align: "left",
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return !isNaN(Number(val)) ? val.toFixed(2) + "%" : val;
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) + "%" : val;
          }
        }
      },
      xaxis: {
        labels: {
          show: true,
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) + "%" : val;
          }
        }
      },
      annotations: {
        xaxis: [
          {
            x: 20,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: 'red'
              },
              text: 'Máximo aceitável (20%)'
            }
          }
        ]
      }
    };

    const epsRechSeriesData = data.rech_por_eps.map((item: any) => ({
      x: item['eps'],
      y: item['Rech']
    }));
    console.log(epsRechSeriesData)
    this.chartOptionsNPSCorrelation = {
      series: [
        {
          name: "RECH",
          data: epsRechSeriesData
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: -100,
                to: -46,
                color: "#F15B46"
              },
              {
                from: -45,
                to: 0,
                color: "#FEB019"
              }
            ]
          },
          columnWidth: "80%"
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return !isNaN(Number(val)) ? val.toFixed(2) + "%" : val;
        }
      },
      title: {
        text: "Rech por EPS",
        align: "left",
      },
      yaxis: {
        title: {
          text: "Média Rech"
        },
        labels: {
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) + "%" : val;
          }
        }
      },
      annotations: {
        yaxis: [
          {
            y: 13,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: 'red'
              },
              text: 'Máximo aceitável (13%)'
            }
          }
        ]
      },
      xaxis: {
        labels: {
          formatter: function (val: any) {
            return !isNaN(Number(val)) ? val.toFixed(2) + "%" : val;
          }
        }
      },
    };

  }

  getFormattedText(text: string): string {
    return text.replace(/\n/g, '<br>');
  }

  createInsight() {
    this.getOpenAIResponseStream({ 'insight': this.insight, 'data': this.data })
    // this.loadAi = true
    // let msg = {
    //   // 'role': 'user',
    //   // 'content': JSON.stringify({ 'insight': this.insight, 'data': this.data })
    // }
    // this.service.aiInsights(this.data, this.insight).subscribe({
    //   next: result => {
    //     this.rawData.insightAi = result.result
    //     this.insightAi = this.getFormattedText(result.result)
    //     this.save(this.rawData)
    //     this.loadAi = false
    //   },
    //   error: err => console.log(err)
    // })
  }

}


