import { Component, OnInit } from '@angular/core';
import { SmartmailService } from 'src/app/services/smartmail.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-smartmail',
  templateUrl: './smartmail.component.html',
  styleUrls: ['./smartmail.component.scss']
})
export class SmartmailComponent implements OnInit {

  smartmails: any = []
  page = 1
  order = 'desc'
  query = ''
  template = 0
  skeleton = true

  constructor(
    private service: SmartmailService
  ) { }

  ngOnInit(): void {
    this.get()
  }

  get() {
    this.service.get({
      limit:    null, 
      query:    this.query,
      order:    this.order,
      page:     this.page,
      template: this.template 
    }).subscribe({
      next: (result) => {
        this.smartmails = result
        this.skeleton   = false
      },
      error: (err) => console.log(err)
    })
  }

  delete(item: any) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover a email ${item.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText:  'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        if (item.id) {
          this.service.delete(item.id).subscribe({
            next: () => this.get(),
            error: (err) => console.log(err)
          })
        } 
      }
    })
  }

  setOrder(order: any) {
    this.order = order
    this.get()
  }

  setPage(p: number) {
    this.page = p
    this.get()
  }

  setSearch(query: string) {
    this.query = query
    this.get()
  }

  setTemplate(template: number) {
    this.template = template
    this.get()
  }

}
