import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
    private http: HttpClient
  ) { }

  get(filter: any = null) {
    return this.http.post(`${URL_API}/tracking`, filter)
  }

  getAllAccessTracking() {
    return this.http.get(`${URL_API}/tracking/smartmails/all`)
  }

  getAccessTrackingInsights(data:any){
    return this.http.post(`${URL_API}/tracking/smartmails/insights`, data);
  }

  getBySmartMailId(smartmail_id: number) {
    return this.http.get(`${URL_API}/tracking/${smartmail_id}`)
  }

  getLinksBySmartMailId(smartmail_id: number) {
    return this.http.get(`${URL_API}/tracking/${smartmail_id}/links`)
  }

  getAccessTimeBySmartMailId(smartmail_id: number) {
    return this.http.get(`${URL_API}/tracking/${smartmail_id}/access`)
  }

  getAccessTimeLine() {
    return this.http.get(`${URL_API}/tracking/smartmails/timeline`)
  }

  export(smartmail_id: number) {
    return this.http.get(`${URL_API}/tracking/smartmails/${smartmail_id}/export`)
  }

}
