<app-page-default title="Smartmail">
    <app-box-status></app-box-status>
    <app-default-table
        pageTitle="SMARTMAIL"
        tableTitle="Emails"
        urlNewItem="/smartmail/new"
        textNewItem="Novo email"
        editRouter="/smartmail/edit"
        [smartmail]="true"
        [record]="smartmails"
        [skeleton]="skeleton" 
        (eventSearch)="setSearch($event)"
        (eventDelete)="delete($event)"
        (eventOrder)="setOrder($event)"
        (eventSetTemplate)="setTemplate($event)"
        (eventPage)="setPage($event)">
    </app-default-table>
</app-page-default>