<main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100 bg-gradient-primary" style="background: url(https://alamo.com.vc/wp-content/uploads/2022/01/img-vagas.jpg); background-size: cover;">
        <span class="mask bg-gradient-dark opacity-8"></span>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-7">
                    <div class="card border-0 mb-0">
                        <div class="card-header bg-transparent pb-0">
                            <!-- <h5 class="text-dark text-center mt-2">Álamo Gestor</h5> -->
                            <img src="https://alamo.com.vc/wp-content/uploads/2021/08/logo.svg" class="d-block m-auto" alt="">
                        </div>
                        <div class="card-body px-lg-5 pt-2">
                            <div class="text-center text-muted mb-4">
                                <small>Faça o login em sua conta</small>
                            </div>
                            <form [formGroup]="loginForm" class="text-start">
                                <div class="mb-3">
                                    <input type="email" class="form-control" formControlName="email" placeholder="Email" aria-label="Email">
                                </div>
                                <div class="mb-3">
                                    <input type="password" class="form-control" formControlName="password" placeholder="Senha" aria-label="Password">
                                </div>
                                <!-- <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="rememberMe">
                                    <label class="form-check-label" for="rememberMe">Lembrar-me</label>
                                </div> -->
                                <div class="text-center">
                                    <button type="submit" 
                                        (click)="login(loginForm)"
                                        [disabled]="loading || !loginForm.valid"
                                        class="btn btn-success w-100 my-4 mb-2">
                                        Entrar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>