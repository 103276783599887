<app-page-default title="Smartmail">
    <!-- <app-box-status></app-box-status> -->
    <div class="row align-items-center text-center" *ngIf="!total">
        <div class="col-lg-3">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                [theme]="{height: '104px' ,marginBottom: '0', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-lg-3">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                [theme]="{height: '104px' ,marginBottom: '0', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-lg-3">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                [theme]="{height: '104px' ,marginBottom: '0', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-lg-3">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                [theme]="{height: '104px' ,marginBottom: '0', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
        </div>
    </div>
    <div class="row align-items-center text-center" *ngIf="total">
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">Smartmails</p>
                        <h5 class="font-weight-bolder">{{ total.smartmails | number }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">Visualizações</p>
                        <h5 class="font-weight-bolder">{{ total.access | number }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">Cliques</p>
                        <h5 class="font-weight-bolder">{{ total.clicks | number }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">CTR</p>
                        <h5 class="font-weight-bolder">{{ total.ctr | number: '0.2-2' }}%</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4" *ngIf="loading">
        <div class="card-body">
            <div class="text-center d-block py-5">
                <small>Trazendo informações...</small>
                <div class="d-flex justify-content-center">
                  <ng-lottie [options]="options" height="400px" (animationCreated)="animationCreated($event)"></ng-lottie>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4" *ngIf="tracking.data?.length > 0 && !loading">
        <div class="card-header pb-0 pt-3 bg-transparent">
            <h6 class="text-capitalize mb-0">Smartmail</h6>
            <p class="text-xs text-secondary mb-0">Veja os últimos envios</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <hr>
                    <apx-chart
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [theme]="chartOptions.theme" 
                        [dataLabels]="chartOptions.dataLabels"
                        [plotOptions]="chartOptions.plotOptions"
                        [legend]="chartOptions.legend"
                        [grid]="chartOptions.grid"
                        [noData]="chartOptions.noData"
                        [xaxis]="chartOptions.xaxis"
                        [yaxis]="chartOptions.yaxis"
                    ></apx-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4" *ngIf="timeline?.length > 0 && !loading">
        <div class="card-body">
            <apx-chart
                [series]="chartLineOptions.series"
                [chart]="chartLineOptions.chart"
                [xaxis]="chartLineOptions.xaxis"
                [stroke]="chartLineOptions.stroke"
                [tooltip]="chartLineOptions.tooltip"
                [dataLabels]="chartLineOptions.dataLabels"
                [legend]="chartLineOptions.legend"
                [markers]="chartLineOptions.markers"
                [grid]="chartLineOptions.grid"
                [yaxis]="chartLineOptions.yaxis"
                [title]="chartLineOptions.title"
          ></apx-chart>
        </div>
    </div>
   
    <div class="card mt-4" *ngIf="!loading">
        <div class="card-body">
            <input type="search" placeholder="Busque aqui" (keyup.enter)="searchItens(search.value)" #search class="form-control">
            <div class="table-responsive p-0 mt-4" *ngIf="tracking.data.length > 0">
                <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7">Data</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Envio</th>
                            <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7">Visualizações</th>
                            <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7">Cliques</th>
                            <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7">CTR</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of tracking.data">
                            <td class="align-middle text-center text-sm">{{ t.created_at | date: 'dd/MM/yyyy' }}</td>
                            <td>
                                <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm text-wrap">{{ t.name }}</h6>
                                    </div>
                                </div>
                            </td>
                            <td class="align-middle text-center text-sm">{{ t.access }}</td>
                            <td class="align-middle text-center text-sm">{{ t.clicks }}</td>
                            <td class="align-middle text-center text-sm">{{ t.ctr | number: '0.2-2' }}%</td>
                            <td class="align-middle text-center text-sm">
                                <a [routerLink]="'/analytics/smartmail/detail/' + t.id" class="btn btn-primary btn-sm mb-0"> <i class="ni ni-chart-pie-35 text-sm opacity-10"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <nav aria-label="Page navigation example" *ngIf="tracking?.last_page > 1">
                <ul class="pagination justify-content-end mt-4">
                    <li class="page-item active" 
                        *ngFor="let p of pagination"
                        [class.active]="tracking.current_page == p">
                            <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="card-body"  *ngIf="tracking.data.length == 0">
            <p class="mb-0 text-center">Nenhuma informação no momento.</p>
        </div>
    </div>
</app-page-default>