<app-page-default title="Gestores">
  <app-box-status></app-box-status>
  <app-default-table
    pageTitle="GESTORES ÁLAMO"
    tableTitle="Gestores"
    urlNewItem="/managers/new"
    textNewItem="Novo gestor"
    editRouter="/managers/edit"
    [record]="managers"
    [skeleton]="skeleton" 
    (eventSearch)="setSearch($event)"
    (eventDelete)="delete($event)"
    (eventOrder)="setOrder($event)"
    (eventPage)="setPage($event)">
  </app-default-table>
</app-page-default>