import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-smartdata',
  templateUrl: './smartdata.component.html',
  styleUrls: ['./smartdata.component.scss']
})
export class SmartdataComponent implements OnInit {

  smartdatas: any = []
  skeleton = true
  query: any
  order = 'desc'
  page = 1

  constructor(
    private service: DataService
  ) { }

  ngOnInit(): void {
    this.get()
  }

  get() {
    this.service.get({
      order: this.order,
      query: this.query,
      page: this.page
    }).subscribe({
      next: result => {
        console.log(result)
        this.smartdatas = result
        this.skeleton = false
      },
      error: err => console.log(err)
    })
  }

  delete(item: any) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover o QR Code ${item.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        if (item.id) {
          this.service.delete(item.id).subscribe({
            next: ()=> this.get()
          })
        }
      }
    })
  }

  setOrder(order: any) {
    this.order = order
    this.get()
  }

  setPage(p: number) {
    this.page = p
    this.get()
  }

  setSearch(query: string) {
    this.query = query
    this.get()
  }

}
