<app-page-default title="Smartmail">
    <div class="row">
        <div class="col-lg-7">
            <div class="card">
                <div class="card-header pb-0 pt-3 bg-transparent">
                    <div class="row">
                        <div class="col-lg-8">
                            <h6 class="text-capitalize mb-0">{{ tracking.name }}</h6>
                            <p class="text-xs text-secondary mb-0">Veja os dados do envio</p>
                        </div>
                        <div class="col-lg-4">
                            <button class="btn btn-primary btn-sm w-100" 
                                [disabled]="exportLoading"
                                (click)="export()">
                                <i class="fas fa-file-excel me-2"></i>  
                                {{ exportLoading ? 'Gerando arquivo...' : 'Exportar dados' }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                    <ngx-skeleton-loader 
                        count="1" 
                        *ngIf="skeleton"
                        appearance="line" 
                        [theme]="{height: '430px', width: '100%', marginBottom: '0', borderRadius: '10px'}">
                    </ngx-skeleton-loader>

                    <apx-chart
                        *ngIf="!skeleton"
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [theme]="chartOptions.theme" 
                        [dataLabels]="chartOptions.dataLabels"
                        [plotOptions]="chartOptions.plotOptions"
                        [legend]="chartOptions.legend"
                        [grid]="chartOptions.grid"
                        [noData]="chartOptions.noData"
                        [xaxis]="chartOptions.xaxis"
                        [yaxis]="chartOptions.yaxis"
                     ></apx-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="card">
                <div class="card-header pb-0 pt-3 bg-transparent">
                    <h6 class="text-capitalize mb-0">Taxa de cliques</h6>
                    <p class="text-xs text-secondary mb-0">Quantidade em porcentagem de cliques x abertura</p>
                </div>
                <div class="card-body">

                    <ngx-skeleton-loader 
                        count="1" 
                        *ngIf="skeleton"
                        appearance="line" 
                        [theme]="{height: '406px', width: '100%', marginBottom: '0', borderRadius: '10px'}">
                    </ngx-skeleton-loader>

                    <apx-chart
                    *ngIf="!skeleton"
                    [series]="chartOptionsPercent.series"
                    [chart]="chartOptionsPercent.chart"
                    [theme]="chartOptionsPercent.theme" 
                    [plotOptions]="chartOptionsPercent.plotOptions"
                    [labels]="chartOptionsPercent.labels"
                    [stroke]="chartOptionsPercent.stroke"
                    [fill]="chartOptionsPercent.fill"
                  ></apx-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header pb-0 pt-3 bg-transparent">
            <h6 class="text-capitalize mb-0">Links</h6>
            <p class="text-xs text-secondary mb-0">Links mais acessados</p>
        </div>
        <div class="card-body">

        <ngx-skeleton-loader 
            count="1" 
            *ngIf="skeleton"
            appearance="line" 
            [theme]="{height: '406px', width: '100%', marginBottom: '0', borderRadius: '10px'}">
        </ngx-skeleton-loader>

            <div class="table-responsive table-links p-0" *ngIf="!skeleton">
                <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Link</th>
                            <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7">Cliques</th>
                            <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7">Conversão</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let l of links">
                            <td class="align-middle">
                                <a [href]="l.link" target="_blank" class="text-sm text-truncate">
                                    {{ l.link.length > 100 ? (l.link | slice: 0:100) + '...' : l.link }}
                                </a>
                            </td>
                            <td class="align-middle text-center text-sm">{{ l.clicks }}</td>
                            <td class="align-middle text-center text-sm">
                                {{ ( l.clicks / tracking.access ) * 100 | number }} %
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header pb-0 pt-3 bg-transparent">
            <h6 class="text-capitalize mb-0">Horários de acesso</h6>
            <p class="text-xs text-secondary mb-0">Horário de acesso</p>
        </div>
        <div class="card-body">

            <ngx-skeleton-loader 
                count="1" 
                *ngIf="skeleton"
                appearance="line" 
                [theme]="{height: '406px', width: '100%', marginBottom: '0', borderRadius: '10px'}">
            </ngx-skeleton-loader>

            <apx-chart
                *ngIf="!skeleton"
                [series]="chartOptionsArea.series"
                [chart]="chartOptionsArea.chart"
                [xaxis]="chartOptionsArea.xaxis"
                [yaxis]="chartOptionsArea.yaxis"
                [theme]="chartOptionsArea.theme" 
                [stroke]="chartOptionsArea.stroke"
                [tooltip]="chartOptionsArea.tooltip"
                [dataLabels]="chartOptionsArea.dataLabels"
          ></apx-chart>
        </div>
    </div>
</app-page-default>