import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-kaban',
  templateUrl: './kaban.component.html',
  styleUrls: ['./kaban.component.scss']
})
export class KabanComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
