<app-page-default>
    <app-box-status></app-box-status>
    <div class="row" *ngIf="skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4"> 
                <ngx-skeleton-loader 
                    *ngIf="skeleton"
                    count="2" 
                    appearance="line" 
                    [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
    
                <h6 class="mb-0">Categoria</h6>
                <p class="text-sm mb-0">Gerencie a categoria aqui</p>

                <form [formGroup]="categoryForm">
                    <div class="row align-items-end">
                        <div class="col-lg-12">
                            <label class="form-label mt-4">Nome</label>
                            <input type="text" class="form-control" formControlName="name">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!skeleton && category_id != 0">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
    
                <h6 class="mb-0">Valores</h6>
                <p class="text-sm mb-0">Gerencie os valores da categoria aqui</p>
                <hr class="horizontal dark my-3">
    
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0" *ngIf="category?.values.length > 0">
                        <thead>
                            <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Valor</th>
                                <th style="width: 20%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let v of category?.values">
                                <td><input type="text" class="form-control mb-0 text-sm input-category-value" [disabled]="!v.edit" [class.input-active]="v.edit" value="{{ v.name }}" [(ngModel)]="v.name" (keyup.enter)="v.edit = !v.edit; editValue(v)"></td>
                                <td class="d-flex justify-content-end">
                                    <button class="btn btn-primary btn-sm m-0 me-1" [class.btn-success]="v.edit" (click)="v.edit = !v.edit; editValue(v)">{{ v.edit ? 'Salvar' : 'Editar' }}</button>
                                    <button class="btn btn-danger btn-sm m-0 me-1" (click)="deleteValue(v)">Remover</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <small class="text-center d-block" *ngIf="category?.values.length == 0">
                        Nenhum valor cadastrado até o momento
                    </small>
                </div>
    
                <hr class="horizontal dark my-3">

                <form [formGroup]="categoryValuesForm">
                    <div class="row align-items-end">
                        <div class="col-lg-10">
                            <label class="form-label mt-4">Valor</label>
                            <input type="text" class="form-control" formControlName="value">
                        </div>
                        <div class="col-lg-2">
                            <button type="submit"[disabled]="!categoryValuesForm.valid" (click)="addValue(categoryValuesForm)"
                                class="btn w-100 btn-success m-0">
                                Adicionar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-lg-4">
            <button type="button" 
                    name="button" 
                    [disabled]="loading" 
                    routerLink="/categories" 
                    class="btn btn-light m-0">
                        Voltar
            </button>
        </div>
        <div class="col-lg-8 col-12 mx-auto">
            <div class="d-flex justify-content-end">
                <button type="button" 
                        name="button" 
                        [disabled]="loading" 
                        routerLink="/categories" 
                        class="btn btn-light m-0">
                            Cancelar
                </button>
                <button type="button" 
                        name="button" 
                        [disabled]="loading"
                        (click)="save(categoryForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Salvando' : 'Salvar categoria' }}
                </button>
            </div>
        </div>
    </div>
</app-page-default>