<app-page-default title="Status">
    <app-box-status></app-box-status>
    <app-default-table
      pageTitle="STATUS"
      tableTitle="Status"
      urlNewItem="/status/new"
      textNewItem="Novo status"
      editRouter="/status/edit"
      [record]="status"
      [skeleton]="skeleton"
      (eventSearch)="setSearch($event)"
      (eventDelete)="delete($event)"
      (eventPage)="setPage($event)">
    </app-default-table>
  </app-page-default>