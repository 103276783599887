import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private auth: AuthService, 
    private router: Router
  ) { }

  public canActivate(): boolean | Observable<boolean> {
    if (this.auth.loggedIn()) {
      return true;
    } else {
      this.auth.setRedirectUrl(window.location.pathname);
      return new Observable<boolean>((observer) => {
        this.router.navigate(['login']);
        observer.next(false);
        observer.complete();
      });
    }
  }
}
