import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { ClientsService } from 'src/app/services/clients.service';
import { MetricsService } from 'src/app/services/metrics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  data: any 
  metrics: any = []
  skeleton = true

  constructor(
    private service: CategoryService,
    private metricService: MetricsService
  ) { }

  ngOnInit(): void {
    this.service.get().subscribe({
      next: (result:any) => this.data = result.data,
      error: (err) => console.log(err)
    })
    this.metricService.get().subscribe({
      next: (result) => {
        this.metrics = result
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
    
  }

}
