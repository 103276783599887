<app-page-default title="Smartcode">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header pb-0 pt-3 bg-transparent">
                    <h6 class="text-capitalize mb-0">{{ tracking.name }}</h6>
                    <p class="text-xs text-secondary mb-0">Veja os dados de acesso</p>
                </div>
                <div class="card-body">

                    <ngx-skeleton-loader 
                        count="1" 
                        *ngIf="skeleton"
                        appearance="line" 
                        [theme]="{height: '406px', width: '100%', marginBottom: '0', borderRadius: '10px'}">
                    </ngx-skeleton-loader>

                    <apx-chart
                        *ngIf="!skeleton"
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [theme]="chartOptions.theme" 
                        [dataLabels]="chartOptions.dataLabels"
                        [plotOptions]="chartOptions.plotOptions"
                        [legend]="chartOptions.legend"
                        [grid]="chartOptions.grid"
                        [noData]="chartOptions.noData"
                        [xaxis]="chartOptions.xaxis"
                        [yaxis]="chartOptions.yaxis"
                     ></apx-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-4">
        <div class="card-header pb-0 pt-3 bg-transparent">
            <h6 class="text-capitalize mb-0">Horários de acesso</h6>
            <p class="text-xs text-secondary mb-0">Horário de acesso</p>
        </div>
        <div class="card-body">

            <ngx-skeleton-loader 
                count="1" 
                *ngIf="skeleton"
                appearance="line" 
                [theme]="{height: '406px', width: '100%', marginBottom: '0', borderRadius: '10px'}">
            </ngx-skeleton-loader>

            <apx-chart
                *ngIf="!skeleton"
                [series]="chartOptionsArea.series"
                [chart]="chartOptionsArea.chart"
                [xaxis]="chartOptionsArea.xaxis"
                [yaxis]="chartOptionsArea.yaxis"
                [theme]="chartOptionsArea.theme" 
                [stroke]="chartOptionsArea.stroke"
                [tooltip]="chartOptionsArea.tooltip"
                [dataLabels]="chartOptionsArea.dataLabels"
          ></apx-chart>
        </div>
    </div>
</app-page-default>