import { AfterContentChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-table',
  templateUrl: './default-table.component.html',
  styleUrls: ['./default-table.component.scss']
})
export class DefaultTableComponent implements OnInit, AfterContentChecked {

  @Input() record: any = []
  @Input() skeleton = true
  @Input() tableTitle: any
  @Input() editRouter: any
  @Input() pageTitle: any
  @Input() urlNewItem: any
  @Input() textNewItem = 'Novo item'
  @Input() emptyTableText = 'Nenhum registro encontrado'
  @Input() smartmail = false

  @Output() eventDelete      = new EventEmitter()
  @Output() eventPage        = new EventEmitter()
  @Output() eventOrder       = new EventEmitter()
  @Output() eventSearch      = new EventEmitter()
  @Output() eventSetTemplate = new EventEmitter()

  menuTemplateActive = 0

  pagination: Array<number> = []

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    
  }

  ngAfterContentChecked(){
    if(this.pagination.length == 0) 
    for (let i = 1; i <= this.record?.last_page; i++) {
      this.pagination.push(i)
    }
  }

  delete(r: any) {
    this.eventDelete.emit(r)
  }

  edit(r: any) {
    this.router.navigateByUrl(`${this.editRouter}/${r.id}`)
  }

  goPage(p: number) {
    this.eventPage.emit(p)
  }

  searchItens(query: string) {
    this.eventSearch.emit(query)
  }

  orderItens(order: string) {
    this.eventOrder.emit(order)
  }

  setTemplate(template: number) {
    this.menuTemplateActive = template
    this.eventSetTemplate.emit(template)
  }

}
