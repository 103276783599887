import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(`${URL_API}/users`)
  }

  save(data: FormData) {
    return this.http.post(`${URL_API}/users`, data)
  }

  updateUser(data: FormData) {
    return this.http.post(`${URL_API}/users`, data)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/users/${id}`)
  }

  deleteUser(id:any) {
    return this.http.delete(`${URL_API}/users/${id}`)
  }

  delete(id:any) {
    return this.http.delete(`${URL_API}/users/${id}`)
  }
}

