import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-box-bar-chart',
  templateUrl: './box-bar-chart.component.html',
  styleUrls: ['./box-bar-chart.component.scss']
})
export class BoxBarChartComponent implements OnInit {

  @Input() data: any = []
  @Input() title = ''
  @Input() skeleton = true

  chartOptions: any

  labels: Array<string> = []
  series: Array<number> = []

  constructor() {}
  

  ngOnInit(): void {
    this.data.forEach((element: any) => {
      this.labels.push(element.item)
      this.series.push(element.percent)
    });

    this.chartOptions = {
      series: [
        {
          name: "demandas",
          data: this.series
        }
      ],
      noData: {
        text: "Nenhum dado para exibir",
        align: "center"
      },
      chart: {
        height: 450,
        type: "bar",
        toolbar: {
          show: false
        },
        animations: {
          enabled: true
        },
      },
      theme: {
        palette: 'palette1' // upto palette10
      },
      plotOptions: {
        bar: {
          columnWidth: "65%",
          distributed: true,
          horizontal: true
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: this.labels,
        labels: {
          show: false
        }
      },
    };
  }

}
