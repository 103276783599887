import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class PartsTypesService {

  constructor(
    private http: HttpClient
  ) { }


  get(filter: any) {
    return this.http.post(`${URL_API}/parts/types/filter`, filter)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/parts/types/${id}`)
  }

  save(data: FormData) {
    return this.http.post(`${URL_API}/parts/types`, data)
  }

  delete(id: number) {
    return this.http.delete(`${URL_API}/parts/types/${id}`)
  }
  
}
