import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { AnalyticCategoriesService } from 'src/app/services/analytic-categories.service';
import { CategoryService } from 'src/app/services/category.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-analytic-categories-form',
  templateUrl: './analytic-categories-form.component.html',
  styleUrls: ['./analytic-categories-form.component.scss']
})
export class AnalyticCategoriesFormComponent implements OnInit {

  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
  };
  public Editor = ClassicEditor;
  loading = false;

  // selected itens
  selectedCategories: any = [];

  types: any = [];
  categoryForm: any;
  categoryFilesForm: any;
  category_id = 0;

  skeleton = false;
  category: any;
  selectCategories: any;

  //files
  public files: NgxFileDropEntry[] = [];
  public readonly MAX_FILE_SIZE_MB = 20;
  greaterFiles:any = [];
  category_files:any;

  formData = new FormData();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private service: AnalyticCategoriesService
  ) {}

  async ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      let id = params.get('id');
      if (id) this.getById(id);
    });

    this.categoryForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'id': ['', Validators.required],
      'grouped_by_column': [''],
      'config': [''],
    });

    this.categoryFilesForm = this.formBuilder.group({
      'value': ['', Validators.required],
    });
  }

  getById(id: any) {
    this.category_id = id;
    this.skeleton = true;
    this.service.getById(id).subscribe({
      next: (result: any) => {
        console.log(result);
        this.category = result;
        this.categoryForm.patchValue(result);
        this.category_files = result.files;
        this.skeleton = false;
      },
      error: (err) => console.log(err),
    });
  }

  save(data: any) {
    console.log(data);

    Object.keys(data.controls).forEach(key => {
      const value = data.get(key)?.value;
      
      // Verifica se o valor não é nulo ou indefinido
      if (value !== null && value !== undefined) {
        this.formData.append(key, value);
        console.log(this.formData.getAll(key));
      }
    })


    this.loading = true;
    this.service.save(this.formData).subscribe({
      next: (result: any) => {
      console.log(result);
      this.loading = false;
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Categoria salva com sucesso',
          showConfirmButton: false,
          timer: 1500,
        });
        this.router.navigateByUrl(`analytics/categories/edit/${result.id}`);
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
      },
    });
  }

  addFile(data: any) {
    if (this.category_id) {
      this.service.saveCategoryFile(data.value, this.category_id).subscribe({
        next: () => {
          this.getById(this.category.id);
          this.categoryFilesForm.reset();
        },
        error: (err) => console.log(err),
      });
    }
  }

  deleteValue(value: any) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover o valor ${value.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then((result) => {
      if (result.value) {
        if (value.id) {
          // this.service.deleteCategoryFile(value.id).subscribe({
          //   next: () => this.getById(this.category.id),
          //   error: (err) => console.log(err),
          // });
        }
      }
    });
  }

  editValue(value: any) {
    if (this.category_id) {
      console.log(value);
      if (!value.edit) {
        this.service.updateCategoryFile(value).subscribe({
          next: () => {
            this.getById(this.category.id);
          },
          error: (err) => console.log(err),
        });
      }
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    // this.files = files;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

           let fileSizeMB = file.size / (1024 * 1024);
           if (fileSizeMB > this.MAX_FILE_SIZE_MB) {
             this.greaterFiles.push(`${droppedFile.relativePath} (${fileSizeMB.toFixed(2)} MB)`);
           } else {
            this.greaterFiles = []
            this.files.push(droppedFile);
            console.log(droppedFile.relativePath);
            this.formData.append('files[]', file, droppedFile.relativePath);
        }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event:any){
    console.log(event);
  }

  public fileLeave(event:any){
    console.log(event);
  }

}
