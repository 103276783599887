<app-page-default title="Smartdata">
    <div *ngIf="!data">
        <app-box-status></app-box-status>
        <div class="card mt-4">
            <div class="card-body">
                <div class="mb-3">
                    <label class="form-label">Título</label>
                    <input type="text" class="form-control" [(ngModel)]="title">
                </div>
                <input type="file" class="form-control" (change)="onFileSelected($event)" multiple>
                <!-- <button class="btn btn-primary btn-sm mb-0 ms-3" (click)="onUpload()">Upload</button> -->
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-4">
                <button type="button" 
                        name="button" 
                        routerLink="/analytics/smartdata" 
                        class="btn btn-light m-0">
                            Voltar
                </button>
            </div>
            <div class="col-lg-8 col-12 mx-auto">
                <div class="d-flex justify-content-end">
                    <button type="button" 
                            name="button" 
                            (click)="onUpload()"
                            class="btn bg-gradient-primary m-0 ms-2">
                            Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="data">
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">Operadores</p>
                        <h5 class="font-weight-bolder">{{ data.total_operadores | number }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">Atendidas</p>
                        <h5 class="font-weight-bolder">{{ data.total_atendimentos | number }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">NPS (Média)</p>
                        <h5 class="font-weight-bolder">{{ data.distribuicao_nps.mean | number }}%</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">Rechamanda (Média)</p>
                        <h5 class="font-weight-bolder">{{ data.distribuicao_rech.mean | number }}%</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-4">
            <div class="card">
                <div class="card-body" *ngIf="chartOptionsNPS">
                    <apx-chart
                    [series]="chartOptionsNPS.series"
                    [chart]="chartOptionsNPS.chart"
                    [xaxis]="chartOptionsNPS.xaxis"
                    [title]="chartOptionsNPS.title"
                    [colors]="chartOptionsNPS.colors"
                    [legend]="chartOptionsNPS.legend"
                    [theme]="chartOptionsNPS.theme"
                    [labels]="chartOptionsNPS.labels"
                    [tooltip]="chartOptionsNPS.tooltip"
                    [annotations]="chartOptionsNPS.annotations"
                  ></apx-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body" *ngIf="chartOptionsRechSegmento">
                    <apx-chart
                    [series]="chartOptionsNPSCorrelation.series"
                    [chart]="chartOptionsNPSCorrelation.chart"
                    [dataLabels]="chartOptionsNPSCorrelation.dataLabels"
                    [title]="chartOptionsNPSCorrelation.title"
                    [plotOptions]="chartOptionsNPSCorrelation.plotOptions"
                    [yaxis]="chartOptionsNPSCorrelation.yaxis"
                    [xaxis]="chartOptionsNPSCorrelation.xaxis"
                    [annotations]="chartOptionsNPSCorrelation.annotations"
                  ></apx-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body" *ngIf="chartOptionsNPSSegmento">
                    <apx-chart
                    [series]="chartOptionsNPSSegmento.series"
                    [chart]="chartOptionsNPSSegmento.chart"
                    [dataLabels]="chartOptionsNPSSegmento.dataLabels"
                    [xaxis]="chartOptionsNPSSegmento.xaxis"
                    [yaxis]="chartOptionsNPSSegmento.yaxis"
                    [title]="chartOptionsNPSSegmento.title"
                    [colors]="chartOptionsNPSSegmento.colors"
                    [theme]="chartOptionsNPSSegmento.theme"
                    [tooltip]="chartOptionsNPSSegmento.tooltip"
                    [annotations]="chartOptionsNPSSegmento.annotations"
                  ></apx-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body" *ngIf="chartOptionsTSSegmento">
                    <apx-chart
                    [series]="chartOptionsTSSegmento.series"
                    [chart]="chartOptionsTSSegmento.chart"
                    [dataLabels]="chartOptionsTSSegmento.dataLabels"
                    [xaxis]="chartOptionsTSSegmento.xaxis"
                    [yaxis]="chartOptionsTSSegmento.yaxis"
                    [theme]="chartOptionsTSSegmento.theme"
                    [title]="chartOptionsTSSegmento.title"
                    [colors]="chartOptionsTSSegmento.colors"
                    [plotOptions]="chartOptionsTSSegmento.plotOptions"
                    [tooltip]="chartOptionsTSSegmento.tooltip"
                    [annotations]="chartOptionsTSSegmento.annotations"
                  ></apx-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card" *ngIf="chartOptionsRechSegmento">
                <div class="card-body">
                  <apx-chart
                    [series]="chartOptionsRechSegmento.series"
                    [chart]="chartOptionsRechSegmento.chart"
                    [dataLabels]="chartOptionsRechSegmento.dataLabels"
                    [xaxis]="chartOptionsRechSegmento.xaxis"
                    [yaxis]="chartOptionsRechSegmento.yaxis"
                    [theme]="chartOptionsRechSegmento.theme"
                    [title]="chartOptionsRechSegmento.title"
                    [colors]="chartOptionsRechSegmento.colors"
                    [tooltip]="chartOptionsRechSegmento.tooltip"
                    [annotations]="chartOptionsRechSegmento.annotations"
                  ></apx-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-lg-6">
            <div class="card" *ngIf="chartOptionsTempoEmpresaFamiliaridade">
                <div class="card-body">
                  <apx-chart
                    [series]="chartOptionsTempoEmpresaFamiliaridade.series"
                    [chart]="chartOptionsTempoEmpresaFamiliaridade.chart"
                    [dataLabels]="chartOptionsTempoEmpresaFamiliaridade.dataLabels"
                    [xaxis]="chartOptionsTempoEmpresaFamiliaridade.xaxis"
                    [yaxis]="chartOptionsTempoEmpresaFamiliaridade.yaxis"
                    [plotOptions]="chartOptionsTempoEmpresaFamiliaridade.plotOptions"
                    [theme]="chartOptionsTempoEmpresaFamiliaridade.theme"
                    [title]="chartOptionsTempoEmpresaFamiliaridade.title"
                    [colors]="chartOptionsTempoEmpresaFamiliaridade.colors"
                    [tooltip]="chartOptionsTempoEmpresaFamiliaridade.tooltip"
                    [annotations]="chartOptionsTempoEmpresaFamiliaridade.annotations"
                  ></apx-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card" *ngIf="chartOptionsCargoFamiliaridade">
                <div class="card-body">
                  <apx-chart
                    [series]="chartOptionsCargoFamiliaridade.series"
                    [chart]="chartOptionsCargoFamiliaridade.chart"
                    [dataLabels]="chartOptionsCargoFamiliaridade.dataLabels"
                    [xaxis]="chartOptionsCargoFamiliaridade.xaxis"
                    [yaxis]="chartOptionsCargoFamiliaridade.yaxis"
                    [plotOptions]="chartOptionsCargoFamiliaridade.plotOptions"
                    [theme]="chartOptionsCargoFamiliaridade.theme"
                    [title]="chartOptionsCargoFamiliaridade.title"
                    [colors]="chartOptionsCargoFamiliaridade.colors"
                    [tooltip]="chartOptionsCargoFamiliaridade.tooltip"
                    [annotations]="chartOptionsCargoFamiliaridade.annotations"
                  ></apx-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card" *ngIf="chartOptionsEmpresaFamiliaridade">
                <div class="card-body">
                  <apx-chart
                    [series]="chartOptionsEmpresaFamiliaridade.series"
                    [chart]="chartOptionsEmpresaFamiliaridade.chart"
                    [dataLabels]="chartOptionsEmpresaFamiliaridade.dataLabels"
                    [xaxis]="chartOptionsEmpresaFamiliaridade.xaxis"
                    [yaxis]="chartOptionsEmpresaFamiliaridade.yaxis"
                    [theme]="chartOptionsEmpresaFamiliaridade.theme"
                    [title]="chartOptionsEmpresaFamiliaridade.title"
                    [colors]="chartOptionsEmpresaFamiliaridade.colors"
                    [tooltip]="chartOptionsEmpresaFamiliaridade.tooltip"
                    [annotations]="chartOptionsEmpresaFamiliaridade.annotations"
                  ></apx-chart>
                </div>
            </div>
        </div>
    </div>
<!-- 
    <div class="card mt-4">
        <div class="card-body">
            <label class="form-label">Famfav</label>
            <input type="file" class="form-control">
        </div>
    </div> -->

    <div class="card mt-4" *ngIf="chartOptionsRechSegmento">
        <div class="card-body">
            <div class="d-flex justify-content-center" *ngIf="insightAi == '' && !loadAi">
                <button class="btn btn-primary mb-0" (click)="createInsight()"><i class="fas fa-magic me-2"></i> Gerar Insight com Inteligencia Ariticial</button>
            </div>
            <div class="text-center" *ngIf="loadAi">
              <small>Gerando Insight</small>
              <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
            </div>
            <div class="p-5" style="white-space: pre-wrap;" [innerHtml]="insightAi" *ngIf="insightAi"></div>
        </div>
    </div>
</app-page-default>

<!-- 
Voz
Rechamada = menor que 13%
NPS = 75
Tempo em silêncio = 20%
Cordialidade = (ainda não recebemos)

TMO 630
% Retenção = 87%
Recontato = 10 (Esse indicador estamos acompanhando mas ainda não está como meta)
NS 72%

SAC - VOZ
CSAT = 50

CHAT
Rechamada = menor que 13%
NPS = 75
TMR (Taxa média de retorno) = menor 50 segundos
TMO (tempo médio atendimento) = 1200 
Cordialidade = (ainda não recebemos)

% Retenção = 76%
Recontato = 10 (Esse indicador estamos acompanhando mas ainda não está como meta)
NS 72% -->