import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { LinksService } from 'src/app/services/links.service';
import { TrackingQrcodeService } from 'src/app/services/tracking-qrcode.service';

@Component({
  selector: 'app-qrcode-analytics',
  templateUrl: './qrcode-analytics.component.html',
  styleUrls: ['./qrcode-analytics.component.scss']
})
export class QrcodeAnalyticsComponent implements OnInit {

  loading = true

  options: AnimationOptions = {
    path: '/assets/argon-dashboard-master/assets/animation/animation_ll6pck5b.json'
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  public chartOptions: any
  public chartPieOptions: any

  labels: Array<string> = []
  access: Array<number> = []
  clicks: Array<number> = []
  ctrs:   Array<number> = []

  tracking: any = []

  constructor(
    private service: TrackingQrcodeService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getTracking()
    }, 1000);
  }

  getTracking() {
    this.service.get().subscribe({
      next: (result) => {
        this.tracking = result
        let charts = this.tracking.slice(0, 4)

        charts.forEach((element: any) => {
          if(element) {
            this.labels.push(element.name)
            this.access.push(element.access)
          }
        });

        this.loading = false

        this.chartOptions = {
          series: [
            {
              name: "Acessos",
              data: this.access
            }
          ],
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          chart: {
            height: 400,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            animations: {
              enabled: true
            },
          },
          theme: {
            palette: 'palette4' // upto palette10
          },
          dataLabels: {
            enabled: true,
            formatter: function (val:any) {
              return val?.toFixed(0);
            }
          },
          legend: {
            show: true,
          },
          xaxis: {
            categories: this.labels,
            labels: {
              show: true,
            },
            tooltip: {
              enabled: true
            }
          },
          yaxis: {
            labels: {
              show: true,
              formatter: function (val:any) {
                return val?.toFixed(0);
              }
            },
          },
        };

      },
      error: (err) => console.log(err)
    })
  }

}
