<div class="sidenav-header">
    <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>
    <a class="navbar-brand m-0" target="_blank">
        <img src="https://alamo.com.vc/wp-content/uploads/2021/08/logo.svg" class="d-block m-auto" alt="">
    </a>
</div>
<hr class="horizontal dark mt-0">
<div class="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <div
                    class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i class="ni ni-tv-2 text-warning text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Dashboard</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link " routerLink="/demands" routerLinkActive="active">
                <div
                    class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i class="ni ni-bullet-list-67 text-info text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Demandas</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="solicitations">
            <a class="nav-link " routerLink="/solicitations" routerLinkActive="active">
                <div
                    class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i class="ni ni-bell-55 text-danger text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Solicitações</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link " routerLink="/kanban" routerLinkActive="active">
                <div
                    class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i class="ni ni-app text-info text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Kanban</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link " routerLink="/smartmail" routerLinkActive="active">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i class="ni ni ni-email-83 text-warning text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Smartmail</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link " routerLink="/qrcode" routerLinkActive="active">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i class="ni ni-mobile-button text-warning text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Smartcode</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="user.group_id != 1">
            <a data-bs-toggle="collapse" href="#dropindicadores" class="nav-link collapsed"
                aria-controls="dropindicadores" role="button" aria-expanded="false">
                <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                    <i class="ni ni-chart-pie-35 text-warning text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Indicadores</span>
            </a>
            <div class="collapse" id="dropindicadores">
                <ul class="nav ms-4">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/analytics/categories" routerLinkActive="active" *ngIf="analyticCategories">
                            <span class="sidenav-normal">Categorias</span>
                        </a>
                        <a class="nav-link" routerLink="/analytics/demands" routerLinkActive="active">
                            <span class="sidenav-normal">Analytics</span>
                        </a>
                        <a class="nav-link" routerLink="/analytics/smartmail" routerLinkActive="active">
                            <span class="sidenav-normal">Smartmail</span>
                        </a>
                        <a class="nav-link" routerLink="/analytics/smartdata" routerLinkActive="active">
                            <span class="sidenav-normal">Smartdata</span>
                        </a>
                        <a class="nav-link" routerLink="/analytics/smartcode" routerLinkActive="active">
                            <span class="sidenav-normal">Smartcode</span>
                        </a>
                        <a class="nav-link" routerLink="/analytics/dashtree" routerLinkActive="active">
                            <span class="sidenav-normal">Dashtree</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
   
        <li class="nav-item" *ngIf="user.group_id != 1">
            <a data-bs-toggle="collapse" href="#applicationsExamples" class="nav-link collapsed"
                aria-controls="applicationsExamples" role="button" aria-expanded="false">
                <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                    <i class="ni ni-ui-04 text-warning text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Configurações</span>
            </a>
            <div class="collapse" id="applicationsExamples">
                <ul class="nav ms-4">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/categories" routerLinkActive="active">
                            <span class="sidenav-normal">Categorias</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/status" routerLinkActive="active">
                            <span class="sidenav-normal">Status</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.group_id == 3">
                        <a class="nav-link" routerLink="/part-types" routerLinkActive="active">
                            <span class="sidenav-normal">Formato Peças</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.group_id == 3">
                        <a class="nav-link" routerLink="/managers" routerLinkActive="active">
                            <span class="sidenav-normal">Gestores Álamo</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user.group_id == 3">
                        <a class="nav-link" routerLink="/clients" routerLinkActive="active">
                            <span class="sidenav-normal">Clientes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/users" routerLinkActive="active">
                            <span class="sidenav-normal">Usuários</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link pointer" (click)="logOut()">
                <div
                    class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i class="ni ni-button-power text-danger text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Sair</span>
            </a>
        </li>
    </ul>

    <hr class="horizontal dark mt-5">
    <small class="text-center text-secondary d-block pb-3">{{ version }}</small>
</div>
