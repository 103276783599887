<app-page-default>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4">
                    <h6 class="mb-0">
                        Smartmail
                    </h6>
                    <p class="text-sm mb-0">
                        Crie aqui seu email marketing
                    </p>
                </div>
                <div class="col-lg-8">
                    <div class="d-flex justify-content-end" *ngIf="!skeleton">
                        <button (click)="duplicate()" *ngIf="smartmail_id" class="btn btn-sm btn-secondary me-2"><i class="fas fa-copy"></i> Duplicar</button>
                        <div class="dropdown open" *ngIf="smartmail_id">
                            <button class="btn btn-sm btn-primary dropdown-toggle me-2" 
                                type="button" 
                                id="triggerId" 
                                data-bs-toggle="dropdown"
                                aria-haspopup="true" 
                                aria-expanded="false">
                                    Exportar
                            </button>
                            <div class="dropdown-menu" aria-labelledby="triggerId">
                                <button class="dropdown-item" (click)="exportHtml()" title="Versão sem indicadores">Teste</button>
                                <!-- <button class="dropdown-item" (click)="exportImage()">Imagem</button> -->
                                <button class="dropdown-item" (click)="exportHtml(true)">Html</button>
                            </div>
                        </div>
                        <button (click)="saveDesign()" [disabled]="!title" class="btn btn-sm btn-primary">Salvar Design</button>
                    </div>
                    <div class="text-end" *ngIf="skeleton">
                        <ngx-skeleton-loader 
                            count="3" 
                            appearance="circle" 
                            [theme]="{height: '40px', width: '120px', marginBottom: '1rem', borderRadius: '10px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>
            <hr class="horizontal dark my-3">
            <div class="row align-items-center" *ngIf="!skeleton">
                <div class="col-lg-9">
                    <div class="form-group">
                        <label class="form-label">Título</label>
                        <input type="text" class="form-control" [(ngModel)]="title" placeholder="Escreva aqui o título para o template">
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-check mt-2 form-switch">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="isTemplate" id="template">
                        <label class="form-check-label" for="template">Salvar como template</label>
                    </div>
                </div>
            </div>

            <ngx-skeleton-loader 
                *ngIf="skeleton"
                count="1"
                appearance="line" 
                [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
            </ngx-skeleton-loader>

            <email-editor
                [options]="options"
                (ready)="editorReady()"
            ></email-editor>
        </div>
    </div>
    
    <ng-template #content let-modal>
        <div class="modal-header">
            <h6 class="modal-title mb-0">Baixar imagem</h6>
            <button type="button" class="btn btn-sm btn-primary mb-0" aria-label="Close" (click)="modal.dismiss('Cross click')">x</button>
        </div>
        <div class="modal-body">
            <app-html-view #htmlView [html]="emailRender"></app-html-view>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="htmlView.download()">Baixar PNG</button>
        </div>
    </ng-template>
</app-page-default>
