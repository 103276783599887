import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(`${URL_API}/roles`);
  }

  getByUserId(user_id:number) {
    return this.http.get(`${URL_API}/user/${user_id}/roles`);
  }

  save(user_id:number, roles:any){
    return this.http.post(`${URL_API}/user/${user_id}/roles`, {'roles' : roles});
  }
}