<app-page-default title="QR Code">
    <app-box-status></app-box-status>
    <div class="row mt-4" *ngIf="skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
                <ngx-skeleton-loader 
                    *ngIf="skeleton"
                    count="2" 
                    appearance="line" 
                    [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-8">
            <div class="card h-100">
                <div class="card-body">
                    <h6 class="mb-0">QR Code</h6>
                    <p class="text-sm mb-0">Gerencie o QR Code</p>
                    <form [formGroup]="linkForm">
                        <div class="row align-items-end">
                            <div class="col-lg-12">
                                <label class="form-label mt-4">Nome</label>
                                <input type="text" formControlName="name" class="form-control">
                            </div>
                            <div class="col-lg-12">
                                <label class="form-label mt-4">Url</label>
                                <input type="text" formControlName="link" placeholder="https://" class="form-control">
                                <small class="text-secondary text-xxs">Seu QR Code abrirá essa url.</small>
                            </div>
                            <div class="col-lg-12" *ngIf="finalUrl">
                                <label class="form-label mt-4">Url rastreável</label>
                                <div class="row">
                                    <div class="col-lg-10">
                                        <input type="text" [disabled]="true" [value]="finalUrl" placeholder="https://" class="form-control">
                                    </div>
                                    <div class="col-lg-2">
                                        <button class="btn btn-warning w-100 mb-0" (click)="copyUrl()">Copiar</button>
                                    </div>
                                </div>
                                <small class="text-secondary text-xxs">Utilize essa url para rastrear os acessos.</small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card h-100">
                <div class="card-body">
                    <div class="box-qr text-center">
                        <div *ngIf="!loading">
                            <ngx-qrcode
                                [elementType]="elementType"
                                [width]="800"
                                [errorCorrectionLevel]="correctionLevel"
                                [value]="finalUrl"
                                alt="QR Code">
                            </ngx-qrcode>
                            <i class="fas fa-qrcode mb-4 d-block" *ngIf="!finalUrl"></i>
                        </div>
                        <ng-lottie *ngIf="loading" [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
                        <a [href]="downloadLink" [download]="nameDownloadLink" [class.disabled]="!downloadLink" class="btn btn-warning">
                            <i class="fas fa-download"></i> Download PNG
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-lg-4">
            <button type="button" 
                    name="button" 
                    routerLink="/qrcode" 
                    class="btn btn-light m-0">
                        Voltar
            </button>
        </div>
        <div class="col-lg-8 col-12 mx-auto">
            <div class="d-flex justify-content-end">
                <button type="button" 
                        name="button" 
                        routerLink="/qrcode" 
                        class="btn btn-light m-0">
                            Cancelar
                </button>
                <button type="button" 
                        name="button" 
                        [disabled]="loading"
                        (click)="save(linkForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading"
                                role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Salvando' : 'Salvar e gerar QR Code' }}
                </button>
            </div>
        </div>
    </div>

</app-page-default>