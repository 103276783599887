import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(
    private http: HttpClient
  ) { }

  get(filter: any = null) {
    return this.http.post(`${URL_API}/status/filter`, filter)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/status/${id}`)
  }
  
  save(data: FormData) {
    return this.http.post(`${URL_API}/status`, data)
  }
  
  delete(id: number) {
    return this.http.delete(`${URL_API}/status/${id}`)
  }

  getKanban() {
    return this.http.get(`${URL_API}/kanban`)
  }

  getMetrics(filter: any = null) {
    return this.http.post(`${URL_API}/status/metrics`, filter)
  }

  setNewOrder(data: any) {
    return this.http.post(`${URL_API}/kanban/order`, data)
  }

  setHighlight(data: any) {
    return this.http.post(`${URL_API}/status/set/highlight`, data)
  }

}
