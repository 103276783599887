import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})

export class ClientsService {

  public global_client_id;

  constructor(
    private http: HttpClient,
  ) { 
    this.global_client_id = (localStorage.getItem("global_client_id") ? localStorage.getItem("global_client_id") : '')    
  }

  save(data: FormData) {
    return this.http.post(`${URL_API}/clients`, data)
  }

  get() {
    return this.http.get(`${URL_API}/clients`)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/clients/${id}`)
  }

  getByHash(hash:string) {
    return this.http.get(`${URL_API}/clients/h/${hash}`)
  }

  delete(id:any) {
    return this.http.delete(`${URL_API}/clients/${id}`)
  }

  getClient(){
    return this.http.get(`${URL_API}/clients/select`)
  }

  setClientNavigation(id:any){
    this.global_client_id = id;
    localStorage.setItem('global_client_id', id);
    return this.http.post(`${URL_API}/clients/select`, '')
  }
}
