import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from './permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard {
  constructor(
    private permissionService: PermissionService, private router: Router) 
    {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const requiredPermission = next.data['permission'];

    const isUserMaster = this.permissionService.isUserMaster();

    // Verifica se o usuário tem a permissão necessária
    if (this.permissionService.hasPermission(requiredPermission) || isUserMaster) {
      return true;
    } else {
      // Redireciona para uma página de acesso não autorizado ou faz alguma outra ação
      this.router.navigateByUrl('/error');
      return false;
    }
  }
}
