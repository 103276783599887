import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class PartsService {

  constructor(
    private http: HttpClient
  ) { }

  get(demand_id: number) {
    return this.http.get(`${URL_API}/parts/demands/${demand_id}`)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/parts/${id}`)
  }

  save(data: FormData, demand_id: number) {
    return this.http.post(`${URL_API}/parts/demands/${demand_id}`, data)
  }

  update(data: FormData, id: number) {
    return this.http.post(`${URL_API}/parts/${id}`, data)
  }

  delete(id: number) {
    return this.http.delete(`${URL_API}/parts/${id}`)
  }

}
