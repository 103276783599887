import { Component, OnInit } from '@angular/core';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AnalyticCategoriesService } from 'src/app/services/analytic-categories.service';
import { URL_API } from 'src/app/services/api';
import { MetricsService } from 'src/app/services/metrics.service';
import { StatusService } from 'src/app/services/status.service';
import { TrackingQrcodeService } from 'src/app/services/tracking-qrcode.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  
  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 1,
    selectAllText: 'Selecionar todos',
    unSelectAllText: 'Desmarcar todos',
    searchPlaceholderText: 'Buscar'
  };


  defaultFiltersList: any = ["Demandas","Giro Newsletter","Qrs Code","E-mails"]
  filtersList: any = []
  selectedFilters: any = [];

  client_id:any;
  skeleton = true
  status = ''
  users: any = []
  statusList: any = []
  manager: any = ''
  data: any
  parts: any
  start = ''
  end = ''

  analysisData:any = null;
  categories:any = null;
  chartOptions: any = null
  chartPartsOptions: any = null
  dashGiroWorkplace: any = null

  dashQrCode:any = null;
  dashEmails:any = null;
  dashEmailsGiro:any = null;

  dashboards:any;

  total: any

  qtdDemands = 0
  qtdParts   = 0

  exportLoading = false

  constructor(
    private metricsService: MetricsService,
    private userService: UserService,
    private statusService: StatusService,
    private analyticService: AnalyticCategoriesService,
    private trackingService: TrackingService,
    private trackingQrcodeService: TrackingQrcodeService,
  ) { }

  ngOnInit(): void {

    let localUser = localStorage.getItem('client_id')?.toString();
    if (localUser) this.client_id = JSON.parse(localUser);
    
    if(this.client_id == 2){
      this.getAnalyticCategoriesMetrics();
      this.getSmartmailsMetrics();
      this.getSmartmailsMetrics({query: 'GIRO'});
      this.getQrCodeMetrics();
    }
    this.getMetrics()
    this.getUsers()
    this.getStatus()
    this.getStatusMetrics()
    this.getParts()
    this.getTotal()
  }

  filterIsActive(filter: string) {
    return this.selectedFilters.includes(filter) ? true : false
  }

  getSmartmailsMetrics(filter:any = {}){
    
    filter.start = this.start;
    filter.end = this.end;

    this.trackingService.getAccessTrackingInsights(filter).subscribe({
      next: (result:any) => {

        let series: any = [];
        let labels: any = [];

        series = [
          {
            name: "Acessos",
            data: result.monthly_tracking.map((item:any) => parseInt(item.access))
          },
          {
            name: "Cliques",
            data: result.monthly_tracking.map((item:any) => parseInt(item.links))
          }
        ];

        labels = result.monthly_tracking.map((item:any) => `${item.month}/${item.year}`)

        let chartOptions = {
          series: series as ApexAxisChartSeries,
          chart: {
            type: "bar",
            height: 450,
            animations: {
              enabled: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: labels,
          },
          yaxis: {
            title: {
              text: 'Quantidade de e-mails'
            }
          },
          fill: {
            opacity: 1
          },
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          }
        };

        let access = result.monthly_tracking.reduce((sum:any, item:any) => sum + parseInt(item.access, 10), 0)
        let clicks = result.monthly_tracking.reduce((sum:any, item:any) => sum + parseInt(item.links, 10), 0);

        
        let table = [
          { label: 'Visualizações totais totais', data: access },
          { label: 'Cliques Totais', data: clicks },
          { label: 'CTR', data: `${(clicks == 0 ? 0 : clicks / access * 100).toFixed(2)}%` },
          { label: 'Melhor dia', data: result.best_day ? result.best_day.split('-')[0] : "" },
          { label: 'Melhor horário', data: result.best_time ? result.best_time.time.replace(":", 'h') : "" },
          { label: 'Destaque', data: result.spotlight ? result.spotlight.name.slice(0, 25) : "", class: "fs-5"}
        ];

        if(!filter?.query) 
        this.dashEmails = {
          chartOptions,
          table
        }
        else
        this.dashEmailsGiro = {
          chartOptions,
          table
        }
        
      },
      error: (err:any) => console.log(err)
    })
  }

  getQrCodeMetrics(){
    this.trackingQrcodeService.getAllQrcodeInsights(
      {
        start:   this.start,
        end:     this.end,
        status:  this.status,
        user_id: this.manager
      }
    ).subscribe({
      next: (result: any) => {

        if(result.data){

        
          let series: any = [];
          let labels: any = [];
          
          result.data.forEach((e: any) => {
              series.push({
                name: `${e.month}/${e.year}`,
                data: [e.total] 
              });
              labels.push(`${e.month}/${e.year}`);
          });
          
          let chartOptions = {
            series: series as ApexAxisChartSeries,
            chart: {
              type: "bar",
              height: 450,
              animations: {
                enabled: false
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: true
            },
            xaxis: {
              categories: labels,
            },
            yaxis: {
              title: {
                text: 'Total de Visualizações'
              }
            },
            fill: {
              opacity: 1
            },
            noData: {
              text: "Nenhum dado para exibir",
              align: "center"
            }
          };

          console.log(result);
          
          let table = [
            { label: 'Acessos totais', data: result.total_views },
            { label: 'Melhor dia', data: result.best_day? result.best_day.split('-')[0] : "" },
            { label: 'Melhor horário', data: result.best_time ? result.best_time.replace(":", 'h') : "" }
          ];

          this.dashQrCode = {
            chartOptions,
            table
          }
        }
      },
      error: (error:any) => console.log(error)
    })
  }

  getTotal() {
    this.metricsService.getTotalDemandsAndParts(
      {
        start:   this.start,
        end:     this.end,
        status:  this.status,
        user_id: this.manager
      }
    ).subscribe({
      next: (result: any) => {
        this.total = result

        this.qtdDemands = 0 
        this.qtdParts   = 0

       let countDemands: any = setInterval(() => {
        if (result.qtdDemands > 0) this.qtdDemands++
        if(this.qtdDemands == result.qtdDemands) {
          clearInterval(countDemands)
        }
       }, 5)

       let countParts: any = setInterval(() => {
        if (result.qtdDemands > 0) this.qtdParts++
        if(this.qtdParts >= result.qtdParts) {
          clearInterval(countParts)
        }
       }, 5)

      },
      error: (err: any) => console.log(err)
    })
  }

  getParts() {
    this.chartPartsOptions = null
    this.metricsService.getParts(
      {
        start:   this.start,
        end:     this.end,
        status:  this.status,
        user_id: this.manager
      }
    ).subscribe({
      next: (result: any) => {
        this.parts = result

        let series: any = []
        let labels: any = []
        let colors: any = []

        result.forEach((e: any) => {
          if(e.total > 0) {
            series.push(e.total)
            labels.push(e.format)
          }
        });

        this.chartPartsOptions = {
          series: [
            {
              name: "Peças",
              data: series
            }
          ],
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          chart: {
            type: "bar",
            height: 600,
            toolbar: {
              show: false
            },
          },
          theme: {
            palette: 'palette1' // upto palette10
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: labels
          },
          legend: {
            show: false
          },
          grid: {
            show: false
          },
        };
      },
      error: (err: any) => console.log(err)
    })
  }

  getMetrics() {
    this.skeleton = true
    this.metricsService.get({
      status: this.status,
      start: this.start,
      end: this.end,
      user_id: this.manager
    }).subscribe({
      next: (result: any) => {
        this.data = result
        this.skeleton = false
      },
      error: (err: any) => console.log(err)
    })
  }

  getStatus() {
    this.statusService.get().subscribe({
      next: (result: any) => {
        this.statusList = result.data
      },
      error: (err) => console.log(err)
    })
  }

  getStatusMetrics() {
    this.chartOptions = null
    this.statusService.getMetrics({
      status: this.status,
      start: this.start,
      end: this.end,
      user_id: this.manager
    }).subscribe({
      next: (result: any) => {

        let series: any = []
        let labels: any = []
        let colors: any = []
    
        result.forEach((e: any) => {
          if(e.total > 0) {
            series.push(e.total)
            labels.push(`${e.name} (${e.total})`)
            colors.push(e.color)
          }
        });
    
        this.chartOptions = {
          series: series,
          chart: {
            type: "pie",
            height: 450,
            animations: {
              enabled: false
            }
          },
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          labels: labels,
          fill: {
            opacity: 1
          },
          colors: colors
        };
      },
      error: (err) => console.log(err)
    })
  }

  getUsers() {
    this.userService.get().subscribe({
      next: (result: any) =>  this.users = result.data,
      error: (err) => console.log(err)
    })
  }

  filter() {
    this.skeleton = true
    this.getMetrics()
    this.getStatusMetrics()
    this.getParts()
    this.getTotal()
    if(this.client_id == 2){
      this.getAnalyticCategoriesMetrics();
      this.getQrCodeMetrics();
      this.getSmartmailsMetrics();
      this.getSmartmailsMetrics({query: 'GIRO'});
    }
  }

  print() {
    window.print()
  }

  export() {
    this.exportLoading = true
    this.metricsService.export({}).subscribe({
      next: (result: any) => {
        this.exportLoading = false
        window.open(result.file, 'blank')
      },
      error: (err) => console.log(err)
    })
  }
 
  getDashboard(category:any = null){

  let config: any = {
    folder_key: `analytics/${category.id}/`,
  };

  if (this.start) config.start = this.start;
  if (this.end)   config.end   = this.end;

  if (category.grouped_by_column) config.group_by_column = category.grouped_by_column;
    this.metricsService.getDashboard(config).subscribe({
      next: (result:any) => {
        this.dashboards.push({
          name: category.name,
          ...this.prepareChart(result,category)
      });
      },
      error: (err:any) => {console.log(err)}
    })
  }

  getAnalyticCategoriesMetrics(){
    this.dashboards = [];
    this.filtersList = [...this.defaultFiltersList]
    this.analyticService.get().subscribe({
      next: (result:any) => {
        this.categories = result.data;
        if(this.categories.length > 0){
          if(this.categories)this.categories.forEach((c:any) => {
            this.filtersList.push(c.name);
            this.filtersList = [...this.filtersList];
            //this.selectedFilters = [...this.filtersList];
            this.getDashboard(c);
          });
        }
      }, 
      error: (err:any) => {console.log(err)}
    })
  }

  prepareChart(data: any = null,category: any = null) {

    if (!data || data.length === 0) return;
  
    const grouped_by_column = Boolean(data[0].grouped_by_column);
    const attributes = Object.keys(data[0].data);
    const totRowsLabel = (typeof category.config_json.tot_rows_label != "undefined" ? category.config_json.tot_rows_label : '');
    const chartType =  (typeof category.config_json.chart_type != "undefined" ? category.config_json.chart_type : "bar");

    const validAttributes = attributes.filter(attr =>
      data.every((d: any) => d.data[attr]?.sum !== undefined)
    );
    
    if(grouped_by_column) {
      data.sort((a: any, b: any) => {
        return b.data[validAttributes[0]].sum - a.data[validAttributes[0]].sum;
      });
    }

    const series = validAttributes.map(attr => ({
      name: attr,
      data: data.map((d: any) => d.data[attr]?.sum || 0),
    }));
  
    const categories = data.map((d: any) =>
      grouped_by_column ? d.grouped_label_value : `${d.month}/${d.year}`
    );

    /*let type = "sum";
        if(category.config_json.columns) {
          type = category.config_json.columns[attr]
        }
        return d.data[attr]?.[type] || 0; */
  
    const table = [
      ...series.map(item => {
        
        let qtd = item.data.reduce((acc: any, val: any) => acc + val, 0);
        
        if(category.config_json.columns && category.config_json.columns[item.name] == "med") {
          qtd = qtd / data.reduce((acc: number, e: any) => acc + e.unique_count, 0);
        }
        
        return ({
          name: item.name,
          data: this.toNumber(qtd)
        });

      })
    ];

    if(grouped_by_column) {
      table.push({
        name: `Destaque (${category.grouped_by_column})`,
        data: categories[0] //calcula o total de registros
      });
    }

    if(totRowsLabel) {
      table.push({
        name: totRowsLabel,
        data: data.reduce((acc: number, e: any) => acc + e.unique_count, 0) //calcula o total de registros
      });
    }

    const chartOptions = chartType == "pie"
      ? {
          series: series[0].data,
          chart: { type: "pie", height: 450, animations: { enabled: false } },
          noData: { text: "Nenhum dado para exibir", align: "center" },
          labels: categories,
          fill: { opacity: 1 }
        }
      : {
          series: series,
          chart: { type: 'bar', height: 350 },
          xaxis: { categories: categories, title: { text: '---------'} },
          yaxis: { title: { text: 'Soma dos Atributos' }, logarithmic: true },
          dataLabels: { enabled: false }
        };

    return { chartOptions, table };
  }

  isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  
  toNumber(number: any) {
    return number.toLocaleString('pt-br', {minimumFractionDigits: 0});
  }

}
