import { Component, Input, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { saveAs } from 'file-saver';
import { SlugifyPipe } from 'src/app/pipe/slugify';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-html-view',
  templateUrl: './html-view.component.html',
  styleUrls: ['./html-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HtmlViewComponent implements OnInit {

  @Input() html: any
  downloadLink: any

  constructor(
    private slug: SlugifyPipe
  ) { }

  ngOnInit(): void {
  }

  download() {
    var self = this
    let node: any = document.querySelector("#htmlContent")
    html2canvas(node, { allowTaint: true, useCORS: true }).then(function(canvas) {
      console.log(canvas)
      saveAs(canvas.toDataURL('image/png'), `${self.slug.transform(self.html.title)}`);
    });
  }

}
