import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DemandsService } from 'src/app/services/demands.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-box-kanban',
  templateUrl: './box-kanban.component.html',
  styleUrls: ['./box-kanban.component.scss']
})
export class BoxKanbanComponent implements OnInit {

  @Output() updateBoxStatus = new EventEmitter()

  status: any = []
  skeleton = true

  constructor(
    private service: DemandsService,
    private statusService: StatusService
  ) { }

  ngOnInit(): void {
    this.getStatus()
  }

  getStatus() {
    this.statusService.getKanban().subscribe({
      next: (result) => { 
        this.status = result
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  drop(event: CdkDragDrop<string[]>, status: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.service.updateStatus({ id: event.item.data.id, status_id: status.id }).subscribe({
        next:  () => this.updateBoxStatus.emit(true),
        error: (err) => console.log(err)
      })
    }
  }

  onColumnDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.status, event.previousIndex, event.currentIndex);
    const ids = this.status.map((item: any) => item.id);
    this.statusService.setNewOrder({ array_ids: ids }).subscribe({
      next: () => {},
      error: (err) => console.log(err)
    })
  }

}
