import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RolesService } from '../roles.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private userPermissions: any;
  private user: any;

  constructor(private rolesService: RolesService) {
    let localUser = localStorage.getItem('user')?.toString();
    if (localUser) this.user = JSON.parse(localUser);

    let roles = localStorage.getItem('roles')?.toString()
    if(roles) this.userPermissions = JSON.parse(roles).map((role:any) => role.slug)
    console.log(this.userPermissions)
  }

  hasPermission(feature: string): boolean {
    if (
      this.userPermissions.includes(feature) ||
      this.userPermissions == feature
    )
      return true;
    else return false;
  }

  getUserPermissions(): string[] {
    return this.userPermissions;
  }

  isUserMaster(): boolean {
    return this.user && this.user.group_id != 1 ;
  }
}
