<app-page-default title="Smartcode">
    <app-box-status></app-box-status>
    <div class="card mt-4" *ngIf="loading">
        <div class="card-body">
            <div class="text-center d-block py-5">
                <small>Trazendo informações...</small>
                <div class="d-flex justify-content-center">
                  <ng-lottie [options]="options" height="400px" (animationCreated)="animationCreated($event)"></ng-lottie>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-4" *ngIf="tracking.length > 0 && !loading">
        <div class="card-header pb-0 pt-3 bg-transparent">
            <h6 class="text-capitalize mb-0">Smartcode</h6>
            <p class="text-xs text-secondary mb-0">Veja os últimos QR Codes</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <hr>
                    <apx-chart
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [theme]="chartOptions.theme" 
                        [dataLabels]="chartOptions.dataLabels"
                        [plotOptions]="chartOptions.plotOptions"
                        [legend]="chartOptions.legend"
                        [grid]="chartOptions.grid"
                        [noData]="chartOptions.noData"
                        [xaxis]="chartOptions.xaxis"
                        [yaxis]="chartOptions.yaxis"
                    ></apx-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-4" *ngIf="!loading">
        <div class="card-body"  *ngIf="tracking.length == 0">
            <p class="mb-0 text-center">Nenhuma informação no momento.</p>
        </div>
        <div class="card-body" *ngIf="tracking.length > 0">
            <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">QR Code</th>
                            <th class="text-uppercase text-secondary text-center text-xxs font-weight-bolder opacity-7">Acessos</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of tracking">
                            <td>
                                <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ t.name }}</h6>
                                    </div>
                                </div>
                            </td>
                            <td class="align-middle text-center text-sm">{{ t.access }}</td>
                            <td class="align-middle text-center text-sm">
                                <a [routerLink]="'/analytics/smartcode/detail/' + t.id" class="btn btn-primary btn-sm mb-0"> <i class="ni ni-chart-pie-35 text-sm opacity-10"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</app-page-default>