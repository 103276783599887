import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OptionsService } from 'src/app/services/options.service';
import { DemandsService } from 'src/app/services/demands.service';
import { PartsService } from 'src/app/services/parts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { NgSelectConfig } from '@ng-select/ng-select';
import { UserService } from 'src/app/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesService } from 'src/app/services/roles.service';


@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent implements OnInit {

  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name'
  };
  public Editor = ClassicEditor;
  loading = false

  // selected itens
  selectedCategories: any = []

  types: any = []
  userForm: any
  user_id = 0
  roles:any;

  skeleton = false
  user: any;
  currentUser: any;
  selectCategories: any
  userValuesForm:any;
  client_id:any;
  selectedClient: any

  groups = [
    {
      name: 'Editor',
      id: 1
    },
    {
      name: 'Administrador',
      id: 2
    },
    {
      name: 'Master',
      id: 3
    },
  ]


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private service: UserService,
    public modalService: NgbModal,
    private rolesService:RolesService
  ) { }

  async ngOnInit() {

    let localUser = localStorage.getItem('user')?.toString()
    if(localUser) this.currentUser = JSON.parse(localUser)

    let client_id = localStorage.getItem('client_id')?.toString()
    this.selectedClient = client_id ? client_id : this.currentUser.client_id

    if(this.currentUser.group_id != 3 || this.selectedClient != 1) 
    this.groups = this.groups.filter((item: any) => item.id !== 3)

    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) {
        this.getById(id)
        this.getRoles(id)
      }
    })
    
    this.userForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'email': ['', Validators.required],
      'password': ['', Validators.required],
      'id': ['', Validators.required],
      'group_id': ['', Validators.required]
    })

    this.userValuesForm = this.formBuilder.group({
      'value': ['', Validators.required]
    })

    this.userForm.controls['group_id'].setValue(0);

  }

  getRoles(id:any){
    this.rolesService.getByUserId(id).subscribe({
      next: (result: any) => {
        this.roles = result
      },
      error: (err) => console.log(err)
    })
  }

  saveRoles(){
    let roles = this.roles.filter((role:any) => role.active)
    this.rolesService.save(this.user.id, roles).subscribe({
      next: (result: any) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Permissões salvas com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
      },
      error: (err) => console.log(err)
    })
  }

  getById(id: any) {
    this.user_id = id
    this.skeleton = true
    this.service.getById(id).subscribe({
      next: (result: any) => {
        if(this.currentUser.group_id !== 3 && result.group_id == 3) {
          this.router.navigateByUrl(`/users`)
          return;
        }
        this.user = result;
        this.userForm.patchValue(result)
        this.skeleton = false
        console.log(result)
      },
      error: (err) => console.log(err)
    })
  }

  save(data: any) {
    console.log(data.value)
   
    
    if(data.value.group_id == 0) {
      Swal.fire({
        icon: 'warning',
        text: 'Selecione um nível de acesso',
        showConfirmButton: true,
      })
      return;
    }

    this.loading = true
    this.service.save(data.value).subscribe({
      next: (result: any) => {
        this.loading = false
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Usuário salva com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl(`/users`)
      },
      error: (err) => {
        console.log(err)
        this.loading = false
      }
    })
  }

}
