<app-page-default title="Indicadores">
    <app-box-status></app-box-status>
    <div class="row align-items-center my-4">
        <div class="col-2">
            <select class="form-select" aria-label="form-select-lg" [(ngModel)]="status">
                <option value="">Status</option>
                <option *ngFor="let s of statusList" [value]="s.id">{{ s.name }}</option>
            </select>
        </div>
        <div class="col-2">
            <select class="form-select" aria-label="form-select-lg" [(ngModel)]="manager">
                <option value="">Responsável</option>
                <option *ngFor="let m of users" [value]="m.id">{{ m.name }}</option>
            </select>
        </div>
        <div class="col-2"><input type="date" class="form-control" [(ngModel)]="start"></div>
        <div class="col-2"><input type="date" class="form-control" [(ngModel)]="end"></div>
        <div class="col-2"><button class="btn btn-success w-100 mb-0" (click)="filter()"><i class="fas fa-filter me-2"></i> Filtar</button></div>
        <div class="col-2">
            <button class="btn btn-dark w-100 mb-0" 
                (click)="export()">
                    <i class="fas fa-file-excel me-2"></i> 
                    {{ exportLoading ? 'Gerando arquivo...' : 'Exportar' }}
            </button>
        </div>
    </div>

    <div class="row align-items-center text-center" *ngIf="total">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">Demandas</p>
                        <h5 class="font-weight-bolder">{{ qtdDemands | number }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="numbers">
                        <p class="text-sm mb-0 text-uppercase font-weight-bold">Peças</p>
                        <h5 class="font-weight-bolder">{{ qtdParts | number }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-lg-12 mb-4">
            <ngx-skeleton-loader 
                *ngIf="skeleton" 
                count="1" 
                appearance="line"
                [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
            <div class="card" *ngIf="!skeleton && chartPartsOptions">
                <div class="card-header pb-0 pt-3 bg-transparent">
                    <h6 class="text-capitalize mb-0">Peças</h6>
                    <p class="text-xs text-secondary mb-0">Quantidade de peças utilizadas</p>
                </div>
                <div class="card-body p-3" *ngIf="chartPartsOptions != null">
                    <apx-chart
                        [series]="chartPartsOptions.series"
                        [chart]="chartPartsOptions.chart"
                        [dataLabels]="chartPartsOptions.dataLabels"
                        [plotOptions]="chartPartsOptions.plotOptions"
                        [theme]="chartPartsOptions.theme" 
                        [noData]="chartPartsOptions.noData"
                        [grid]="chartPartsOptions.grid"
                        [legend]="chartPartsOptions.legend" 
                        [xaxis]="chartPartsOptions.xaxis"
                    ></apx-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-4">
            <ngx-skeleton-loader 
                *ngIf="skeleton" 
                count="1" 
                appearance="line"
                [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
            <div class="card z-index-2 h-100" *ngIf="!skeleton">
                <div class="card-header pb-0 pt-3 bg-transparent">
                    <h6 class="text-capitalize mb-0">Status</h6>
                    <p class="text-xs text-secondary mb-0">Porcentagem de demandas</p>
                </div>
                <div class="card-body p-3" *ngIf="chartOptions != null">
                    <apx-chart 
                        [series]="chartOptions.series" 
                        [chart]="chartOptions.chart" 
                        [labels]="chartOptions.labels"
                        [noData]="chartOptions.noData"
                        [fill]="chartOptions.fill"
                        [colors]="chartOptions.colors">
                    </apx-chart>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-4" *ngIf="skeleton">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-lg-6 mb-4" *ngFor="let pie of data">
            <app-box-pie-chart 
                [title]="pie.name" 
                [data]="pie.percents">
            </app-box-pie-chart>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4" *ngIf="skeleton">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-lg-12 mb-4" *ngFor="let bar of data">
            <app-box-bar-chart 
                [title]="bar.name" 
                [data]="bar.percents">
            </app-box-bar-chart>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4" *ngIf="skeleton">
            <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
        </div>

    </div>
    
    <ng-container *ngIf="dashboards">
        <div class="row mt-4" *ngFor="let d of dashboards">
            <div class="col-12">
                <p class="d-inline-flex gap-1">
                    <button class="btn btn-primary btn-dashboard-collapse" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#dash-'+d.name.replace(' ', '-').toLowerCase()" aria-expanded="false" [attr.aria-controls]="'dash-'+d.name.replace(' ', '-').toLowerCase()">
                        <i class="fa-solid fa-caret-down me-2"></i> {{d.name}}
                    </button>
                </p>
            </div>
            <div class="row collapse show" [id]="'dash-'+d.name.replace(' ', '-').toLowerCase()">
            <div class="col-6">
                <div class="card card-body">
                    <div id="chart">
                        <apx-chart *ngIf="d.chartOptions?.chart?.type === 'pie'"
                        [series]="d.chartOptions.series"
                        [chart]="d.chartOptions.chart"
                        [labels]="d.chartOptions.labels"
                        [fill]="d.chartOptions.fill"
                        [noData]="d.chartOptions.noData">
                      </apx-chart>
                        <apx-chart *ngIf="d.chartOptions?.chart?.type === 'bar'"
                            [series]="d.chartOptions.series"
                            [chart]="d.chartOptions.chart"
                            [xaxis]="d.chartOptions.xaxis"
                            [yaxis]="d.chartOptions.yaxis"
                            [title]="d.chartOptions.title"
                            [tooltip]="d.chartOptions.tooltip"
                            [dataLabels]="d.chartOptions.dataLabels"
                        ></apx-chart>
                    </div>
                </div>
            </div>
                <div class="col-6" *ngIf="d?.table">
                    <div class="row h-100">
                        <div class="col-6" *ngFor="let c of d?.table">
                            <div class="card">
                                <div class="card-body py-3">
                                    <p class="fs-8 mb-0">{{c.name}}</p>
                                    <h2 class="text-center">{{c.data | number}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="row mt-4" *ngIf="dashQrCode">
        <div class="col-12">
            <p class="d-inline-flex gap-1">
                <button class="btn btn-primary btn-dashboard-collapse" type="button" data-bs-toggle="collapse" data-bs-target="#dash-qrcode" aria-expanded="false" aria-controls="dash-qrcode">
                    <i class="fa-solid fa-caret-down me-2"></i> Qrs Code
                </button>
            </p>
        </div>
        <div class="row collapse show" id="dash-qrcode">
        <div class="col-6">
            <div class="card card-body">
                <div id="chart">
                    <apx-chart
                        [series]="dashQrCode.chartOptions.series"
                        [chart]="dashQrCode.chartOptions.chart"
                        [xaxis]="dashQrCode.chartOptions.xaxis"
                        [yaxis]="dashQrCode.chartOptions.yaxis"
                        [title]="dashQrCode.chartOptions.title"
                        [tooltip]="dashQrCode.chartOptions.tooltip"
                        [dataLabels]="dashQrCode.chartOptions.dataLabels"
                    ></apx-chart>
                </div>
            </div>
        </div>
            <div class="col-6">
                <div class="row h-100">
                    <div class="col-12" *ngFor="let c of dashQrCode?.table">
                        <div class="card">
                            <div class="card-body py-3">
                                <p class="fs-8 mb-0">{{c.label}}</p>
                                <h2 class="text-center">
                                    {{ isNumber(c.data) ? (c.data | number) : c.data }}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>

    <div class="row mt-4" *ngIf="dashEmails">
        <div class="col-12">
            <p class="d-inline-flex gap-1">
                <button class="btn btn-primary btn-dashboard-collapse" type="button" data-bs-toggle="collapse" data-bs-target="#dash-emails" aria-expanded="false" aria-controls="dash-emails">
                    <i class="fa-solid fa-caret-down me-2"></i> E-mails
                </button>
            </p>
        </div>
        <div class="row collapse show" id="dash-emails">
        <div class="col-6" *ngIf="dashEmails?.chartOptions">
            <div class="card card-body">
                <div id="chart">
                    <apx-chart
                        [series]="dashEmails.chartOptions.series"
                        [chart]="dashEmails.chartOptions.chart"
                        [xaxis]="dashEmails.chartOptions.xaxis"
                        [yaxis]="dashEmails.chartOptions.yaxis"
                        [title]="dashEmails.chartOptions.title"
                        [tooltip]="dashEmails.chartOptions.tooltip"
                        [dataLabels]="dashEmails.chartOptions.dataLabels"
                    ></apx-chart>
                </div>
            </div>
        </div>
            <div class="col-6">
                <div class="row h-100">
                    <div class="col-6 d-flex align-items-center" *ngFor="let c of dashEmails?.table">
                        <div class="card w-100">
                            <div class="card-body py-3">
                                <p class="fs-8 mb-0">{{c.label}}</p>
                                <h2 class="text-center {{c?.class}}">
                                    {{ isNumber(c.data) ? (c.data | number) : c.data }}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>

    <div class="row mt-4" *ngIf="dashEmailsGiro">
        <div class="col-12">
            <p class="d-inline-flex gap-1">
                <button class="btn btn-primary btn-dashboard-collapse" type="button" data-bs-toggle="collapse" data-bs-target="#dash-emails" aria-expanded="false" aria-controls="dash-emails">
                    <i class="fa-solid fa-caret-down me-2"></i> E-mails GIRO
                </button>
            </p>
        </div>
        <div class="row collapse show" id="dash-emails">
        <div class="col-6" *ngIf="dashEmailsGiro?.chartOptions">
            <div class="card card-body">
                <div id="chart">
                    <apx-chart
                        [series]="dashEmailsGiro.chartOptions.series"
                        [chart]="dashEmailsGiro.chartOptions.chart"
                        [xaxis]="dashEmailsGiro.chartOptions.xaxis"
                        [yaxis]="dashEmailsGiro.chartOptions.yaxis"
                        [title]="dashEmailsGiro.chartOptions.title"
                        [tooltip]="dashEmailsGiro.chartOptions.tooltip"
                        [dataLabels]="dashEmailsGiro.chartOptions.dataLabels"
                    ></apx-chart>
                </div>
            </div>
        </div>
            <div class="col-6">
                <div class="row h-100">
                    <div class="col-6 d-flex align-items-center" *ngFor="let c of dashEmailsGiro?.table">
                        <div class="card w-100">
                            <div class="card-body py-3">
                                <p class="fs-8 mb-0">{{c.label}}</p>
                                <h2 class="text-center {{c?.class}}">
                                    {{ isNumber(c.data) ? (c.data | number) : c.data }}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
   
</app-page-default>