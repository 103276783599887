<app-page-default title="Categorias">
    <app-box-status></app-box-status>
    <app-default-table
      pageTitle="CATEGORIAS DE INDICADORES"
      tableTitle="Categorias de indicadores"
      urlNewItem="/analytics/categories/new"
      textNewItem="Nova categoria de indicadores"
      editRouter="/analytics/categories/edit"
      [record]="categories"
      [skeleton]="skeleton"
      (eventSearch)="setSearch($event)"
      (eventDelete)="delete($event)"
      (eventOrder)="setOrder($event)"
      (eventPage)="setPage($event)">
    </app-default-table>
  </app-page-default>