import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailEditorComponent } from 'angular-email-editor';
import { SmartmailService } from 'src/app/services/smartmail.service';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { FileSaverService } from 'ngx-filesaver';
import { SlugifyPipe } from 'src/app/pipe/slugify';
import { URL_API } from 'src/app/services/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-smartmail-editor',
  templateUrl: './smartmail-editor.component.html',
  styleUrls: ['./smartmail-editor.component.scss']

})
export class SmartmailEditorComponent implements OnInit {

  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;

  title = ''
  isTemplate = 0
  smartmail_id: any = ''
  content: any
  skeleton = true

  downLoadImg = ''
  emailRender: any

  options = {
    locale: 'pt-BR',
    projectId: 176888
  }

  constructor(
    private service: SmartmailService,
    private route: ActivatedRoute,
    private router: Router,
    private fileSaverService: FileSaverService,
    private slugifyPipe: SlugifyPipe,
    private clipboard: Clipboard,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
    ) { 
    this.emailEditor = new EmailEditorComponent
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) this.getById(id)
    })
  }

  getById(id: any) {
    this.service.getById(id).subscribe({
      next: (result: any) => {
        this.title        = result.name
        this.content      = JSON.parse(result.content)
        this.smartmail_id = result.id,
        this.isTemplate   = result.template
        if(!this.skeleton) this.emailEditor.editor.loadDesign(this.content)
      },
      error: (err) => console.log(err)
    })
  }

  editorReady() {
    this.emailEditor.editor.loadDesign(this.content)
    this.skeleton = false
  }

  exportHtml(prod = false) {
    this.emailEditor.editor.exportHtml((data: any) => {
      const regex = /<a\s+[^>]*href=["']([^"']*)["'][^>]*>/gi;
      let pixel = `<img src="${URL_API}/email/access/tracking/smartmail/${this.smartmail_id}"></body>`;
      let urlTrackingBase = `${URL_API}/email/access/tracking/smartmail/link/${this.smartmail_id}?link=`;
      let html: any = data.html
        .replace('</body>', pixel)
        .replace('width:100%', 'max-width:100%')
        .replace(regex, (match: any, url: any) => {
          const encodedURL = encodeURIComponent(url);
          return `<a href="${urlTrackingBase}${encodedURL}"${match.substr(2)}`; 
        });
      this.fileSaverService.save(prod ? html : data.html.replace(`</head>`, `<h4 style="text-align: center; background: red; padding: 10px; color: #fff">VERSÃO TESTE</h4>`), `${this.slugifyPipe.transform(this.title)}.html`);
    });
  }

  exportImage() { 
  }

  saveDesign(copy = false) {
    this.emailEditor.editor.saveDesign((data: any) => {

      const form = new FormData();
      form.set('title', this.title);
      form.set('id', this.smartmail_id);
      form.set('template', JSON.stringify(data));
      form.set('isTemplate', this.isTemplate.toString());

      this.service.save(form).subscribe({
        next: (result: any) => {

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: copy ? 'Template duplicado com sucesso' : 'Template salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
          
          this.router.navigateByUrl(`/smartmail/edit/${result.id}`)
        },
        error: (err) => {
          console.log(err)
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            text: `Erro ao salvar, tente novamente em instantes: ${err.message}`
          })
        }
      })

    });
  }

  duplicate() {
    Swal.fire({
      icon: 'warning',
      text: `Deseja duplicar o email ${this.title}?`,
      showCancelButton: true,
      confirmButtonText: 'Duplicar',
      cancelButtonText: 'Cancelar'
    }).then(result => {
      if (result.value) {
       this.smartmail_id = ''
       this.title = `Cópia de: ${this.title}`
       this.saveDesign(true)
      }
    })
  }

}
