<app-page-default title="Usuários">
  <app-box-status></app-box-status>
  <app-default-table
    pageTitle="USUÁRIOS"
    tableTitle="Usuários"
    urlNewItem="/users/new"
    textNewItem="Novo usuário"
    editRouter="/users/edit"
    [record]="users"
    [skeleton]="skeleton"
    (eventSearch)="setSearch($event)"
    (eventDelete)="delete($event)"
    (eventOrder)="setOrder($event)"
    (eventPage)="setPage($event)">
  </app-default-table>
</app-page-default>