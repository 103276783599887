import { Component, OnInit } from '@angular/core';
import { TrackingService } from 'src/app/services/tracking.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-smartmail-analytics',
  templateUrl: './smartmail-analytics.component.html',
  styleUrls: ['./smartmail-analytics.component.scss']
})
export class SmartmailAnalyticsComponent implements OnInit {

  loading = true

  options: AnimationOptions = {
    path: '/assets/argon-dashboard-master/assets/animation/animation_ll6pck5b.json'
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  total: any = null

  public chartOptions: any
  public chartLineOptions: any
  public chartPieOptions: any

  labels: Array<string> = []
  access: Array<number> = []
  clicks: Array<number> = []
  ctrs: Array<number> = []

  tracking: any = []
  timeline: any = []
  pagination: Array<number> = []

  page = 1
  query = ''

  constructor(private service: TrackingService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getTracking()
      this.getAllTracking()
      this.getAccessTimeLine()
    }, 1000);
  }

  getAllTracking() {
    this.service.getAllAccessTracking().subscribe({
      next: (result) => {
        this.total = result
        console.log(result)
      },
      error: (err) => console.log(err)
    })
  }

  getAccessTimeLine() {
    this.service.getAccessTimeLine().subscribe({
      next: (result: any) => {
        console.log(result)
        this.timeline = result

        let access: any = []
        let clicks: any = []
        let time:   any = []

        result.forEach((element: any) => {
          if(element) {
            access.push(element.views)
            clicks.push(element.clicks)
            time.push(element.month_year)
          }
        });

        this.chartLineOptions = {
          series: [
            {
              name: "Acessos",
              data: access
            },
            {
              name: "Cliques",
              data: clicks
            }
          ],
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true
          },
          stroke: {
            width: 5,
            curve: "smooth",
            dashArray: [0, 10]
          },
          title: {
            text: "Acesso mensal",
            align: "left"
          },
          legend: {
            tooltipHoverFormatter: function(val: any, opts: any) {
              return (
                val +
                " - <strong>" +
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                "</strong>"
              );
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            labels: {
              trim: false,
              show: true,
              formatter: function (val: any) {
                let date = new Date(`${val}-01`)
                let month = (date.getMonth() + 2).toString()
                let year  = date.getFullYear()
                if(parseInt(month) < 10) month = `0${month}`
                return `${month}/${year}`;
              }
            },
            categories: time
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function(val: any) {
                    return val;
                  }
                }
              },
              {
                title: {
                  formatter: function(val: any) {
                    return val;
                  }
                }
              },
            ]
          },
          grid: {
            borderColor: "#f1f1f1"
          }
        };


      },
      error: (err) => console.log(err)
    })
  }

  getTracking(searchOrPage = false) {
    this.service.get(
      {
        page: this.page,
        query: this.query
      }
    ).subscribe({
      next: (result: any) => {
        this.tracking = result

        if(!searchOrPage) {
          let charts = this.tracking.data.slice(0, 3)
          
          if (this.pagination.length == 0)
            for (let i = 1; i <= result.last_page; i++) {
              this.pagination.push(i)
            }
  
          charts.forEach((element: any) => {
            if (element) {
              console.log(element)
              this.labels.push(element.name)
              this.access.push(element.access)
              this.clicks.push(element.clicks)
              this.ctrs.push(element.ctr)
            }
          });
  
          this.loading = false
  
          this.chartOptions = {
            series: [
              {
                name: "Acessos",
                type: "column",
                data: this.access
              },
              {
                name: "Cliques",
                type: "column",
                data: this.clicks
              },
              // {
              //   name: "Conversão (%)",
              //   type: "column",
              //   data: this.ctrs
              // },
            ],
            noData: {
              text: "Nenhum dado para exibir",
              align: "center"
            },
            chart: {
              height: 400,
              type: "bar",
              stacked: false,
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              },
              animations: {
                enabled: true
              },
            },
            theme: {
              palette: 'palette1' // upto palette10
            },
            dataLabels: {
              enabled: true,
              formatter: function (val: any) {
                return val?.toFixed(0);
              }
            },
            legend: {
              show: true,
            },
            xaxis: {
              categories: this.labels,
              labels: {
                show: true,
              },
              tooltip: {
                enabled: true
              }
            },
            yaxis: {
              labels: {
                show: true,
                formatter: function (val: any) {
                  return val?.toFixed(0);
                }
              },
            },
          };
        }

      },
      error: (err) => console.log(err)
    })
  }

  goPage(p: number) {
    this.page = p
    this.getTracking(true)
  }

  searchItens(query: string) {
    this.query = query
    this.getTracking(true)
  }

}
