import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient
  ) { }

  get(filter: any = null) {
    return this.http.post(`${URL_API}/categories/filter`, filter)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/categories/${id}`)
  }

  updateStatus(data: any) {
    return this.http.post(`${URL_API}/categories/status`, data)
  }

  save(data: FormData) {
    return this.http.post(`${URL_API}/categories`, data)
  }

  update(data: FormData, id: number) {
    return this.http.post(`${URL_API}/categories/${id}`, data)
  }

  delete(id: number) {
    return this.http.delete(`${URL_API}/categories/${id}`)
  }

  saveCategoryValue(data: FormData, category_id:number) {
    return this.http.post(`${URL_API}/categories/${category_id}/values`, data)
  }

  deleteCategoryValue(id: number) {
    return this.http.delete(`${URL_API}/categories/values/${id}`)
  }

  updateCategoryValue(data:any) {
    return this.http.post(`${URL_API}/categories/values/${data.id}`, data)
  }
}
