<app-page-default>
    <app-box-status></app-box-status>
    <div class="row" *ngIf="skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
                <ngx-skeleton-loader 
                    *ngIf="skeleton"
                    count="2" 
                    appearance="line" 
                    [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
    
                <h6 class="mb-0">Status</h6>
                <p class="text-sm mb-0">Gerencie os status</p>

                <form [formGroup]="statusForm">
                    <div class="row align-items-end">
                        <div class="col-lg-6">
                            <label class="form-label mt-4">Status</label>
                            <input type="text" class="form-control" formControlName="name">
                        </div>

                        <div class="col-2">
                            <label class="form-label mt-4">Ícone</label>
                            <div 
                                [iconPicker]="icon" 
                                [ipIconPack]="['fa','fa5']"
                                ipPlaceHolder="Busque aqui"
                                (iconPickerSelect)="onIconPickerSelect($event)"
                                class="form-control text-center">
                                <i [class]="icon"></i>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <label class="form-label mt-4">Cor</label>
                            <input type="color" class="form-control form-color" formControlName="color">
                        </div>
                        <div class="col-lg-6 mt-4">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" formControlName="default" id="default">
                                <label class="form-check-label" for="default">Valor padrão para demandas</label>
                            </div>
                        </div>
                        <div class="col-lg-6 mt-4">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" formControlName="kanban" id="kanban">
                                <label class="form-check-label" for="kanban">Exibir no Kanban</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-lg-4">
            <button type="button" 
                    name="button" 
                    [disabled]="loading" 
                    routerLink="/status" 
                    class="btn btn-light m-0">
                        Voltar
            </button>
        </div>
        <div class="col-lg-8 col-12 mx-auto">
            <div class="d-flex justify-content-end">
                <button type="button" 
                        name="button" 
                        [disabled]="loading" 
                        routerLink="/status" 
                        class="btn btn-light m-0">
                            Cancelar
                </button>
                <button type="button" 
                        name="button" 
                        [disabled]="loading || !statusForm.valid"
                        (click)="save(statusForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Salvando' : 'Salvar status' }}
                </button>
            </div>
        </div>
    </div>
</app-page-default>