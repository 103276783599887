import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  constructor(
    private http: HttpClient
  ) { }

  get(filter: any = null){
    return this.http.post(`${URL_API}/metrics`, filter)
  }

  getDashTree() {
    return this.http.get(`${URL_API}/dashtree`)
  }

  getParts(filter: any = null) {
    return this.http.post(`${URL_API}/metrics/parts`, filter)
  }

  getTotalDemandsAndParts(filter: any = null) {
    return this.http.post(`${URL_API}/metrics/total`, filter)
  }

  export(filter: any = null) {
    return this.http.post(`${URL_API}/metrics/export`, filter)
  }

  getDashboard(data:any = null){
    data.production = window.location.href.includes('smarthub.alamo.com.vc') ? true : false;
    console.log(data);
    return this.http.post(`https://analyse.smarthub.alamo.com.vc/analyze`, data)
  }

}
