import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/services/status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  skeleton = true
  page = 1
  query = ''
  status: any = []

  constructor(
    private service: StatusService
  ) { }

  ngOnInit(): void {
    this.get()
  }

  get() {
    this.service.get({
      page: this.page,
      query: this.query
    }).subscribe({
      next: (result) => {
        this.status = result
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  setPage(p: number) {
    this.page = p
    this.get()
  }

  setSearch(query: string) {
    this.query = query
    this.get()
  }

  delete(item: any) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover o status ${item.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        if (item.id) {
          this.service.delete(item.id).subscribe({
            next: () => this.get(),
            error: (err) => console.log(err)
          })
        } 
      }
    })
  }

}
