<div class="card z-index-2 h-100 mt-4">
  <div class="card-header pb-2 pt-3 bg-transparent">
    <div class="row align-items-center">
      <div class="col-2">
        <h6 class="text-capitalize mb-0">{{ title }}</h6>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <input type="text" placeholder="Busque aqui" (keyup.enter)="searchItens(search.value)" #search class="form-control">
      </div>
      <div class="col-2 d-flex justify-content-end">
        <select class="form-control" #order (change)="orderItens(order.value)">
          <option value="desc">Mais recentes</option>
          <option value="asc">Mais antigas</option>
        </select>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <a routerLink="/calendar" class="btn btn-xs btn-primary mb-0 me-2" *ngIf="!solicitations">
          <i class="fas fa-calendar pe-2" aria-hidden="true"></i> Calendário
        </a>
        <a routerLink="/demands/new" class="btn btn-xs btn-primary mb-0" *ngIf="!solicitations">
          <i class="fas fa-plus pe-2" aria-hidden="true"></i> Nova demanda
        </a>
        <!-- <a class="btn btn-xs btn-primary mb-0" (click)="copyToClipboard()" *ngIf="solicitations">
          <i class="fas fa-plus pe-2" aria-hidden="true"></i> Compartilhar
        </a> -->
      </div>
    </div>
  </div>
  <div class="card-body p-3 pb-4">  

    <ngx-skeleton-loader 
      *ngIf="skeleton"
      count="5" 
      appearance="line" 
      [theme]="{height: '45px'}">
    </ngx-skeleton-loader>
    
    <div class="table-responsive p-0" *ngIf="!skeleton">
        <table class="table align-items-center mb-0" *ngIf="demands?.data.length > 0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Demanda</th>
              <th></th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Peças</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Data</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of demands.data" class="pointer" [routerLink]="'/demands/edit/' + d.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm py-2"><small class="pe-1">{{ d.job_code }}</small> {{ d.name }}</h6>
                    <!-- <p class="text-xs text-secondary mb-0" *ngIf="d.user">{{ d.user.name }}</p> -->
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm bg-gradient-danger" *ngIf="d.priority">Prioridade</span>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm bg-gradient-primary">{{ d.total_parts }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm" *ngIf="d?.status" [style.background]="d.status.color + '1A'" [style.color]="d.status.color" [style.border-color]="d.status.color">{{ d.status.name }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ d.date | date: "dd/MM/yyyy" }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="demands?.data.length == 0">
          <small>{{ emptyTableText }}</small>
          <div class="d-flex justify-content-center">
            <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
          </div>
        </div>
    </div>
    <nav aria-label="Page navigation example" *ngIf="demands.last_page > 1 && !limit">
        <ul class="pagination justify-content-end mt-4">
            <li class="page-item active" *ngFor="let p of pagination"
                [class.active]="demands.current_page == p">
                <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
            </li>
        </ul>
    </nav>
  </div>
</div>


