<main class="main-content position-relative pb-6" id="solicitation">
    <div class="container pt-5">
        <div class="row">
            <div class="col-4 text-center d-flex align-items-center justify-content-center">
                <img src="assets/solicitations/logo-carrefour.png" alt="">
            </div>
            <div class="col-4 text-center d-flex align-items-center justify-content-center">
                <img src="assets/solicitations/logo-giro.png" alt="">
            </div>
        </div>
    </div>
    <div class="container py-4 pt-2 mt-4">
        <div class="row mt-4">
            <div class="col-lg-12 col-12 mx-auto">
                <div class="card card-body z-index-2 h-100 pb-4 px-5">
                    <ngx-skeleton-loader 
                        *ngIf="skeleton"
                        count="5" 
                        appearance="line" 
                        [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
                    </ngx-skeleton-loader>
                    <form [formGroup]="demandForm" *ngIf="!skeleton"  enctype="multipart/form-data">
                        <div class="row align-items-end">
                            <div class="col-lg-12 text-center mb-4">
                                <h2 class="title">Formulário de Solicitações</h2>
                                <p class="text-lg fw-bold mb-0" style="color: #4B4B4B">Olá! quer fazer uma solicitação de publicação no Giro? Tá no lugar certo!<br>E para isso vamos precisar de algumas informações, ok?</p>
                            </div>
                        </div>
                        <div class="row align-items-end">
                            <div class="col-lg-12 text-center px-5">
                                <p class="text-lg" style="color: #828282">Lembrando que essa solicitação é direcionada apenas para publicações do grupo do Giro, que é exclusivamente no Workplace. Acesse <a href="https://carrefour.workplace.com/groups/1331067064199918">aqui</a> o grupo. Caso necessite de outras formas de comunicação, fale diretamente com o nosso time de Comunicação Interna</p>
                            </div>
                        </div>
                        <div class="row align-items-end mb-4">
                            <div class="col-lg-12 text-center px-10">
                                <p class="text-md observation">Em até 2 dias úteis após o recebimento da solicitação, você recebrá o conteúdo do post para aprovação (desde que esteja com as informações completas). Após aprovação, até 1 dia útil para publicação no grupo do Giro no Workplace</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-8">
                                <label class="form-label text-sm">Título da Solicitação</label>
                                <input type="text" class="form-control" formControlName="name">
                            </div>
                            <div class="col-lg-4">
                                <label class="form-label text-sm">Endereço de e-mail</label>
                                <input type="email" class="form-control" formControlName="requester" [disabled]="true">
                            </div>
                        </div>

                        <div class="row mt-4">
                            <ng-container>
                            <div class="col-lg-6" *ngFor="let c of categories; let i = index">
                                <label class="form-label text-sm">{{c.name}}</label>
                                
                                <ng-multiselect-dropdown
                                    [placeholder]="'Selecione os itens'"
                                    [settings]="dropdownSettings"
                                    [data]="c.values"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="c.selecteds">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="col-lg-6">
                                <label class="form-label text-sm">Sub área / Departamento</label>
                                <input type="text" class="form-control" formControlName="department">
                            </div>
                        </ng-container>
                        </div>

                        <div class="row mt-4">
                            <div class="col-12">
                                <label class="form-label text-sm" for="">Essa demanda já está sendo tratada pela área de Comunicação?
                                    <br>
                                    <span class="font-italic">Ex: Campanhas já planejadas ou ativas, outros comunicados criados, etc.</span>
                                </label>
                                <div class="form-group d-flex">
                                    <div class="form-check me-7">
                                        <input class="form-check-input" type="radio" formControlName="handled" name="handled" [(ngModel)]="handled" id="handled_true" [value]="1">
                                        <label class="form-check-label" for="handled_true">
                                        Sim
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="handled" name="handled" [(ngModel)]="handled" id="handled_false" [value]="0">
                                        <label class="form-check-label" for="handled_false">
                                        Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr class="horizontal dark my-4">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-lg-12">
                                <label class="form-label text-sm">Briefing</label>
                                <textarea placeholder="Escreva em detalhes as informações da sua publicação" [(ngModel)]="briefing" class="form-control" rows="12" formControlName="purpose"></textarea>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-lg-3">
                                <label class="form-label text-sm">Data limite para divulgação</label>
                                <input type="date" class="form-control" [min]="demandForm.controls['date'].value" formControlName="end">
                            </div>
                        </div>
    
                        <div class="row mt-4">
                            <div class="col-lg-12">
                                <label class="form-label text-sm mb-0">Anexo arquivos de apoio</label>
                                <p class="fw-normal text-darkblue">(Documentos, referências, logotipo e etc.)</p>
                                <div class="center">
                                    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
                                    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [multiple]="true">
                                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                            <button type="button" (click)="openFileSelector()" class="d-block bg-white border-0">
                                                <img src="assets/solicitations/upload-icon.png" alt="">
                                            </button>
                                                Arraste aqui o arquivo ou procure em seu computador
                                        </ng-template>
                                    </ngx-file-drop>
                                    <div class="upload-table" *ngIf="files.length > 0">
                                        <table class="table">
                                            <tbody class="upload-name-style">
                                                <tr *ngFor="let item of files; let i=index">
                                                    <td><p class="mb-0">{{ item.relativePath }}</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="greaterFiles.length > 0">
                            <div class="col-12">
                                <p class="text-sm text-danger fw-bold mb-0 mt-2">Os arquivos abaixo não foram carregados pois ultrapassam o limite máximo de {{MAX_FILE_SIZE_MB}} MB</p>
                                <p><small class="text-danger" *ngFor="let f of greaterFiles">{{f}}</small></p>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <label class="form-label text-sm">Esse conteúdo é recorrente?</label>
                                <div class="form-group d-flex align-items-center">
                                    <div class="form-check mb-0 me-7">
                                        <input class="form-check-input" type="radio" name="recurring"
                                        formControlName="recurring" id="recurring_yes"  formControlName="recurring" [(ngModel)]="recurring" [value]="1">
                                        <label class="form-check-label m-0" for="recurring_yes">
                                        Sim
                                        </label>
                                    </div>
                                    <div class="form-group d-flex align-items-center m-0">
                                        <label for="" class="text-secondary text-nowrap m-0 me-3">Por quanto tempo?</label>
                                        <ng-multiselect-dropdown
                                            [placeholder]="'Selecione a recorrência'"
                                            [settings]="recurrenceIntervaldropdownSettings"
                                            [data]="recurrence_interval_data"
                                            [disabled]="!recurring || recurring == ''"
                                            formControlName="recurrence_interval"
                                            style="width: 288px">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="form-check mb-0 ms-3">
                                        <input class="form-check-input" type="radio" name="recurring"
                                        formControlName="recurring" id="recurring_no" formControlName="recurring" [(ngModel)]="recurring" [value]="0" (click)="recurrence_interval = ''">
                                        <label class="form-check-label" for="recurring_no">
                                        Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="recurring" class="horizontal dark my-3 mt-4">
                        <div class="row my-5" *ngIf="recurring">
                            <div class="col-12 text-center">
                                <button class="btn-blue text-sm"
                                type="button" 
                                name="button" 
                                [disabled]="loading || !demandForm.valid"
                                (click)="save(demandForm)">
                                <span class="spinner-grow spinner-grow-sm" 
                                    *ngIf="loadingSolicitation" role="status" 
                                    aria-hidden="true">
                                </span>
                                {{ loadingSolicitation ? 'Solicitando para equipe' : 'Solicitar para equipe' }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </div>
        <div class="container ai-container z-0" *ngIf="recurring === 0">
            <div class="row">
                <div class="col-12">
                    <div class="card ai-card z-index-2 pb-4 mt-5">
                        <div class="card-header pb-9 text-center px-10 ai-header">
                                <h2 class="text-white">Gostaria de insights da nossa IA? <img src="assets/solicitations/ai-icon.png" class="ms-2"></h2>
                                <p class="text-md text-white">
                                    Com a nossa Inteligência Artificial, você pode elaborar os próprios conteúdos e aprová-
                                    los. Os textos sugeridos pela IA também permitem edições, caso seja necessário.
                                    Se nenhuma opção for adequada, é só clicar no botão “Solicitar para equipe” que o time de
                                    Comunicação dará cuidará da elaboração do conteúdo.
                                </p>
                        </div>
                        <div class="card-body overflow-hidden container-fluid px-0">
                            <p class="col-12 text-center text-left text-md">
                                Importante: Todo conteúdo passará por uma revisão antes da publicação final.
                            </p>
                                <p class="col-12 text-center">
                                    <button class="btn-green pointer text-md me-2" (click)="generateInsights()" [disabled]="briefing.length < 50">
                                        <span class="spinner-grow spinner-grow-sm" 
                                            *ngIf="loadAi" role="status" 
                                            aria-hidden="true">
                                        </span>
                                        {{ loadAi ? 'Gerando insights da IA' : 'Gerar insights da IA' }}
                                    </button>
                                    <button class="btn-blue pointer text-md"
                                    type="button" 
                                    name="button" 
                                    [disabled]="loading || !demandForm.valid"
                                    (click)="save(demandForm)">
                                        <span class="spinner-grow spinner-grow-sm" 
                                            *ngIf="loadingSolicitation" role="status" 
                                            aria-hidden="true">
                                        </span>
                                {{ loadingSolicitation ? 'Solicitando para equipe' : 'Solicitar para equipe' }}</button>
                                </p>
                                <ng-container *ngIf="insights">
                                    <p class="text-lg mt-6 fw-bold text-darkblue text-center mb-0">Escolha a melhor opção</p>
                                    <p class="text-lg text-darkblue text-center mb-5">(Você pode editar se precisar)</p>
                                </ng-container>
                            
                                <div class="row px-3 insights">
                                <div class="col-4" *ngFor="let i of insights; index as index">
                                    <div class="card" [class.selected]="i.selected" (click)="toggleSelectedInsight(index)">
                                        <div class="card-header py-3 text-white fw-bold d-flex align-items-center justify-content-center">
                                            <i class="fas fa-circle me-3" aria-hidden="true"></i>Opção {{index + 1}}
                                        </div>
                                        <div class="card-body text-sm">
                                            <!-- <textarea class="form-control" rows="20" [(ngModel)]="i.content"></textarea> -->
                                            <ckeditor [editor]="Editor" [config]="editorConfig" [(ngModel)]="i.insight"></ckeditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="insights">
                            <div class="col-12">
                                <hr class="horizontal dark my-3">
                            </div>
                        </div>
                        <div class="card-footer" *ngIf="insights">
                            <div class="col-12 text-center">
                                <button class="btn-gray pointer text-md me-2 py-3" style="width: 408px" (click)="save(demandForm)" [disabled]="briefing.length < 50">
                                    <img src="assets/solicitations/close-icon.png">
                                    <span class="spinner-grow spinner-grow-sm" 
                                        *ngIf="loadingSolicitation" role="status" 
                                        aria-hidden="true">
                                    </span>
                                    {{ loadingSolicitation ? 'Solicitando' : 'Reprovar e solicitar para a equipe' }}
                                </button>
                                <button class="btn-green pointer text-md me-2 py-3" 
                                style="width: 408px"
                                type="button" 
                                name="button" 
                                [disabled]="loading || !demandForm.valid || !selectedInsight"
                                (click)="save(demandForm, true)">
                               <img src="assets/solicitations/check-icon.png">
                                    <span class="spinner-grow spinner-grow-sm" 
                                        *ngIf="loading" role="status" 
                                        aria-hidden="true">
                                    </span>
                            {{ loading ? 'Enviando' : 'Aprovar e enviar' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="container">
        <div class="row mt-5">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6 col-12 mx-auto">
                <div class="d-flex justify-content-end">
                    <button type="button" 
                            name="button" 
                            [disabled]="loading" 
                            routerLink="/demands" 
                            class="btn btn-light m-0">
                                Cancelar
                    </button>
                    <button type="button" 
                            name="button" 
                            [disabled]="loading || !demandForm.valid"
                            (click)="save(demandForm)"
                            class="btn bg-gradient-primary m-0 ms-2">
                                <span class="spinner-grow spinner-grow-sm" 
                                    *ngIf="loading" role="status" 
                                    aria-hidden="true">
                                </span>
                                {{ loading ? 'Salvando' : 'Solicitar' }}
                    </button>
                </div>
            </div>
        </div>
        </div> -->
</main>