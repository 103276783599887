import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-solicitation',
  templateUrl: './solicitation.component.html',
  styleUrls: ['./solicitation.component.scss'],
})
export class SolicitationComponent implements OnInit {
  
  client_id: any
  sharedUrl: any

  constructor(
    private service: ClientsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    let localUser = localStorage.getItem('client_id')?.toString();
    if (localUser) this.client_id = JSON.parse(localUser);
    this.getClient();

    if(localStorage.getItem('client_id') != '2') this.router.navigateByUrl('demands');
  }

  getClient() {
    console.log(this.client_id);
    this.service.getById(this.client_id).subscribe({
      next: (result: any) => {
        this.sharedUrl = `${window.location.origin}/solicitations/${result.hashid}`
      },
      error: (err) => console.log(err),
    });
  }
}
