import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { OptionsService } from 'src/app/services/options.service';
import { DemandsService } from 'src/app/services/demands.service';
import { PartsService } from 'src/app/services/parts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { NgSelectConfig } from '@ng-select/ng-select';
import { MetricsService } from 'src/app/services/metrics.service';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-categories-form',
  templateUrl: './categories-form.component.html',
  styleUrls: ['./categories-form.component.scss'],
})
export class CategoriesFormComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name',
  };
  public Editor = ClassicEditor;
  loading = false;

  // selected itens
  selectedCategories: any = [];

  types: any = [];
  categoryForm: any;
  categoryValuesForm: any;
  category_id = 0;

  skeleton = false;
  category: any;
  selectCategories: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private service: CategoryService
  ) {}

  async ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      let id = params.get('id');
      if (id) this.getById(id);
    });

    this.categoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      id: ['', Validators.required],
    });

    this.categoryValuesForm = this.formBuilder.group({
      value: ['', Validators.required],
    });
  }

  getById(id: any) {
    this.category_id = id;
    this.skeleton = true;
    this.service.getById(id).subscribe({
      next: (result: any) => {
        this.category = result;
        this.categoryForm.patchValue(result);
        this.skeleton = false;
      },
      error: (err) => console.log(err),
    });
  }

  save(data: any) {
    this.loading = true;
    this.service.save(data.value).subscribe({
      next: (result: any) => {
        this.loading = false;
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Categoria salva com sucesso',
          showConfirmButton: false,
          timer: 1500,
        });
        this.router.navigateByUrl(`/categories/edit/${result.id}`);
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
      },
    });
  }

  addValue(data: any) {
    if (this.category_id) {
      this.service.saveCategoryValue(data.value, this.category_id).subscribe({
        next: () => {
          this.getById(this.category.id);
          this.categoryValuesForm.reset();
        },
        error: (err) => console.log(err),
      });
    }
  }

  deleteValue(value: any) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover o valor ${value.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then((result) => {
      if (result.value) {
        if (value.id) {
          this.service.deleteCategoryValue(value.id).subscribe({
            next: () => this.getById(this.category.id),
            error: (err) => console.log(err),
          });
        }
      }
    });
  }

  editValue(value: any) {
    if (this.category_id) {
      console.log(value);
      if (!value.edit) {
        this.service.updateCategoryValue(value).subscribe({
          next: () => {
            this.getById(this.category.id);
          },
          error: (err) => console.log(err),
        });
      }
    }
  }
}
