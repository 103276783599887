<app-page-default>
    <app-box-status></app-box-status>
    <div class="row" *ngIf="skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
                <ngx-skeleton-loader 
                    *ngIf="skeleton"
                    count="5" 
                    appearance="line" 
                    [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
                <h6 class="mb-0">Usuário</h6>
                <p class="text-sm mb-0">Gerencie os dados do usuário aqui</p>
                <form [formGroup]="userForm">
                    <div class="row align-items-end">
                        <div class="col-lg-4">
                            <label class="form-label mt-4">Nome</label>
                            <input type="text" class="form-control" formControlName="name">
                        </div>
                        <div class="col-lg-4">
                            <label class="form-label mt-4">E-mail</label>
                            <input type="text" class="form-control" formControlName="email">
                        </div>
                        <div class="col-lg-4">
                            <label class="form-label mt-4">Senha</label>
                            <input type="password" class="form-control" formControlName="password">
                        </div>
                    </div>
                    <div class="row align-items-end mt-4">
                        <div class="col-lg-12">
                             <label class="form-label mt-1">Nível de acesso</label>

                            <select formControlName="group_id" class="form-control" #group>
                                <option value="0">-- Selecione o nível de acesso --</option>
                                <option *ngFor="let g of groups" [value]="g.id">{{ g.name }}</option>
                            </select>

                            <div class="alert alert-primary mt-4 text-light" role="alert" *ngIf="group.value == '1'">
                                <strong>Editor</strong> 
                                <p class="mb-0">Acesso aos recursos básicos do Smarthub e visualização apenas dos Smartmails criados pelo próprio usuário.</p>
                            </div>
                            <div class="alert alert-warning mt-4 text-light" role="alert" *ngIf="group.value == '2'">
                                <strong>Administrador</strong> 
                                <p class="mb-0">Acesso total ao seu próprio painel, com a capacidade de criar novos administradores e editores.</p>
                            </div>
                            <div class="alert alert-danger mt-4 text-light" role="alert" *ngIf="group.value == '3'">
                                <strong>Master</strong> 
                                <p class="mb-0">Acesso completo a todos os clientes do Smarthub, proporcionando uma administração global. Apenas usuários Álamo.</p>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-lg-4">
            <button type="button" 
                    name="button" 
                    [disabled]="loading" 
                    routerLink="/users" 
                    class="btn btn-light m-0 ms-2">
                        Voltar
            </button>
            <!-- <button type="button" 
                    *ngIf="!user?.master && !skeleton"
                    name="button" 
                    [disabled]="loading"
                    (click)="modalService.open(content, { size: 'xl' });"
                    class="btn bg-gradient-primary m-0 ms-2">
                        Permissões
            </button> -->
        </div>
        <div class="col-lg-8 col-12 mx-auto">
            <div class="d-flex justify-content-end">
                <button type="button" 
                        name="button" 
                        [disabled]="loading" 
                        routerLink="/users" 
                        class="btn btn-light m-0">
                            Cancelar
                </button>
                <button type="button" 
                        name="button" 
                        [disabled]="loading"
                        (click)="save(userForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Salvando' : 'Salvar usuário' }}
                </button>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h6 class="modal-title mb-0">Permissões</h6>
            <button type="button" class="btn btn-sm btn-primary mb-0" aria-label="Close" (click)="modal.dismiss('Cross click')">x</button>
        </div>
        <div class="modal-body px-5">
            <div class="form-check" *ngFor="let r of roles">
                <input class="form-check-input" type="checkbox" value="{{r.id}}" id="{{r.name}}" [checked]="r.active" [(ngModel)]="r.active">
                <label class="form-check-label" for="{{r.name}}">
                  {{r.name}}
                </label>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-primary mb-0" (click)="saveRoles()">Salvar</button>
        </div>
    </ng-template>
    
</app-page-default>