import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-clients-form',
  templateUrl: './clients-form.component.html',
  styleUrls: ['./clients-form.component.scss']
})
export class ClientsFormComponent implements OnInit {

  dropdownSettings: IDropdownSettings = {
    idField: 'id',
    textField: 'name'
  };
  public Editor = ClassicEditor;
  loading = false

  // selected itens
  selectedCategories: any = []

  types: any = []
  clientForm: any
  client_id:any;

  skeleton = false
  user: any;
  selectCategories: any
  userValuesForm:any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private service: ClientsService
  ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) this.getById(id)
    })
    
    this.clientForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'active': [true, Validators.required],
      'id': ['', Validators.required]
    })

    this.userValuesForm = this.formBuilder.group({
      'value': ['', Validators.required]
    })
  }

  getById(id: any) {
    this.client_id = id
    this.skeleton = true
    this.service.getById(id).subscribe({
      next: (result: any) => {
        this.user = result;
        this.clientForm.patchValue(result)
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  save(data: any) {
    console.log(data.value)
    this.loading = true
    this.service.save(data.value).subscribe({
      next: (result: any) => {
        this.loading = false
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Cliente salvo com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
        this.router.navigateByUrl(`/clients`)
      },
      error: (err) => {
        console.log(err)
        this.loading = false
      }
    })
  }

}
