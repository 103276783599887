import { Component, OnInit } from '@angular/core';
import { ApexAxisChartSeries, ChartComponent } from 'ng-apexcharts';
import { MetricsService } from 'src/app/services/metrics.service';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.scss']
})
export class TesteComponent implements OnInit {

  public chartOptions:any;
  analysisData:any;

  constructor(
    private metricsService: MetricsService
  ) {}

  ngOnInit(): void {
    this.getDashboard()
    this.prepareChart();
  }

  getDashboard(){
    this.metricsService.getDashboard().subscribe({
      next: (result:any) => {
        this.analysisData = result;
        this.prepareChart();
      },
      error: (err:any) => {console.log(err)}
    })
  }

  
  prepareChart(): void {
    // Obtém todas as categorias (meses)
    const categories = Object.keys(this.analysisData);

    // Obtém todas as colunas (tipos de dados)
    const columns = Object.keys(this.analysisData[categories[0]]);

    // Filtra categorias válidas e prepara dados
    const validData = categories.reduce((acc, month) => {
      // Verifica se a categoria tem pelo menos um valor válido
      const hasValidValues = columns.some(col => {
        const value = this.analysisData[month][col]?.sum;
        return typeof value === 'number' && !isNaN(value);
      });

      if (hasValidValues) {
        acc[month] = this.analysisData[month];
      }

      return acc;
    }, {} as any);

    // Filtra categorias válidas
    const validCategories = Object.keys(validData);

    // Cria dados da série para o gráfico
    const seriesData = columns.map(column => {
      // Filtra dados válidos para a coluna
      const data = validCategories.map(month => {
        const value = validData[month][column]?.sum;
        return (typeof value === 'number' && !isNaN(value)) ? value : null;
      }).filter(value => value !== null); // Remove valores nulos

      // Inclui a série apenas se tiver dados válidos
      return data.length > 0 ? { name: column, data } : null;
    }).filter(Boolean); // Remove séries nulas

    // Configurações do gráfico
    this.chartOptions = {
      series: seriesData as ApexAxisChartSeries,  // Dados da série
      chart: {
        type: 'bar',  // Tipo de gráfico: colunas
        height: 350
      },
      title: {
        text: 'Métricas por Mês'  // Título do gráfico
      },
      xaxis: {
        categories: validCategories  // Categorias (meses) no eixo X
      },
      yaxis: {
        title: {
          text: 'Valores (Soma)'  // Título do eixo Y
        }
      },
      dataLabels: {
        enabled: false  // Desabilita rótulos de dados
      },
      tooltip: {
        shared: true,  // Tooltip compartilhado entre séries
        intersect: false
      }
    };
  }
}