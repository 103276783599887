import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class TrackingQrcodeService {

  constructor(
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(`${URL_API}/qrcode/tracking`)
  }

  getByLinkId(link_id: number) {
    return this.http.get(`${URL_API}/qrcode/tracking/${link_id}`)
  }

  getAccessTimeByLinkId(link_id: number) {
    return this.http.get(`${URL_API}/qrcode/tracking/${link_id}/access`)
  }

  getAllQrcodeInsights(filter:any = null){
    return this.http.post(`${URL_API}/qrcode/tracking/insights`, filter)
  }
}
