import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StatusService } from 'src/app/services/status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.scss']
})
export class StatusFormComponent implements OnInit {

  skeleton = false
  loading = false

  statusForm: any
  icon: any

  constructor(
    private service: StatusService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) this.getById(id)
    })

    this.statusForm = this.formBuilder.group({
      'name':    ['', Validators.required],
      'kanban':  [true, Validators.required],
      'default': [false, ''],
      'color':   ['#5e72e4'],
      'icon':    [''],
      'id':      ['']
    })

  }

  getById(id: any) {
    this.skeleton = true
    this.service.getById(id).subscribe({
      next: (result: any) => {
        this.statusForm.patchValue(result)
        this.icon = result.icon
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  save(data: any) {
    this.loading = true
    console.log(data)
    this.service.save(data.value).subscribe({
      next: () => {
        this.loading = false

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Status salvo com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.router.navigateByUrl(`/status`)
      },
      error: (error) => {
        console.log(error)
        this.loading = false
      }
    })
  }

  onIconPickerSelect(icon: any) {
    this.icon = icon
    this.statusForm.controls['icon'].setValue(icon)
  }

}
