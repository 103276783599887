<app-page-default>
    <app-box-status></app-box-status>
    <div class="row" *ngIf="skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4"> 
                <ngx-skeleton-loader 
                    *ngIf="skeleton"
                    count="2" 
                    appearance="line" 
                    [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
    
                <h6 class="mb-0">Categoria</h6>
                <p class="text-sm mb-0">Gerencie a categoria aqui</p>

                <form [formGroup]="categoryForm">
                    <div class="row align-items-end">
                        <div class="col-lg-12">
                            <label class="form-label mt-4">Nome</label>
                            <input type="text" class="form-control" formControlName="name">
                        </div>
                        <div class="col-lg-12">
                            <label class="form-label mt-4">Coluna de agrupamento (deixe vazio se o agrupamento for através da data)</label>
                            <input type="text" class="form-control" formControlName="grouped_by_column">
                        </div>
                        <div class="col-lg-12">
                            <label class="form-label mt-4">Configurações</label>
                            <textarea class="form-control" formControlName="config"></textarea>
                        </div>
                    </div>
                </form>                
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!skeleton && category_id != 0">
        <div class="col-lg-12">
            <label class="form-label mb-3">Anexo arquivos de apoio (Documentos, referências, logotipo e etc.)</label>
            <div class="center">
                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
                (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [multiple]="true">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <button type="button" (click)="openFileSelector()" class="d-block bg-white border-0">
                            <img src="assets/solicitations/upload-icon.png" alt="">
                        </button>
                            <p class="text-center mb-0">Arraste aqui o arquivo ou procure em seu computador<br><small>Máximo de 20MB</small></p>
                    </ng-template>
                </ngx-file-drop>
                <div class="upload-table" *ngIf="files.length > 0">
                    <table class="table">
                        <tbody class="upload-name-style">
                            <tr *ngFor="let item of files; let i=index">
                                <td><p class="mb-0">{{ item.relativePath }}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="greaterFiles?.length > 0">
        <div class="col-12">
            <p class="text-sm text-danger fw-bold mb-0 mt-2">Os arquivos abaixo não foram carregados pois ultrapassam o limite máximo de {{MAX_FILE_SIZE_MB}} MB</p>
            <p><small class="text-danger" *ngFor="let f of greaterFiles">{{f}}</small></p>
        </div>
    </div>
    <div class="row mt-4" *ngIf="category_files?.length > 0">
        <div class="col-12">
            <label class="form-label">Arquivos já publicados:</label>
            <a class="d-block" [href]="f.src" *ngFor="let f of category_files" download>{{ f.file_name }}</a>
        </div>
    </div>



    <div class="row mt-5">
        <div class="col-lg-4">
            <button type="button" 
                    name="button" 
                    [disabled]="loading" 
                    routerLink="/analytics/categories" 
                    class="btn btn-light m-0">
                        Voltar
            </button>
        </div>
        <div class="col-lg-8 col-12 mx-auto">
            <div class="d-flex justify-content-end">
                <button type="button" 
                        name="button" 
                        [disabled]="loading" 
                        routerLink="/analytics/categories" 
                        class="btn btn-light m-0">
                            Cancelar
                </button>
                <button type="button" 
                        name="button" 
                        [disabled]="loading"
                        (click)="save(categoryForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Salvando' : 'Salvar categoria' }}
                </button>
            </div>
        </div>
    </div>
</app-page-default>