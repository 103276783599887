import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { DemandsComponent } from './pages/demands/demands.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DemandsFormComponent } from './pages/demands/demands-form/demands-form.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { KabanComponent } from './pages/kaban/kaban.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoriesFormComponent } from './pages/categories/categories-form/categories-form.component';
import { PartTypesComponent } from './pages/part-types/part-types.component';
import { PartTypesFormComponent } from './pages/part-types/part-types-form/part-types-form.component';
import { ManagersComponent } from './pages/managers/managers.component';
import { ManagersFormComponent } from './pages/managers/managers-form/managers-form.component';
import { UsersComponent } from './pages/users/users.component';
import { UsersFormComponent } from './pages/users/users-form/users-form.component';
import { SolicitationFormComponent } from './pages/solicitation/solicitation-form/solicitation-form.component';
import { SolicitationComponent } from './pages/solicitation/solicitation.component';
import { StatusComponent } from './pages/status/status.component';
import { StatusFormComponent } from './pages/status/status-form/status-form.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientsFormComponent } from './pages/clients/clients-form/clients-form.component';
import { SmartmailComponent } from './pages/smartmail/smartmail.component';
import { SmartmailEditorComponent } from './pages/smartmail/smartmail-editor/smartmail-editor.component';
import { SmartmailAnalyticsComponent } from './pages/smartmail/smartmail-analytics/smartmail-analytics.component';
import { DashtreeComponent } from './pages/dashtree/dashtree.component';
import { SmartmailAnalyticsDetailComponent } from './pages/smartmail/smartmail-analytics/smartmail-analytics-detail/smartmail-analytics-detail.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { QrcodeComponent } from './pages/qrcode/qrcode.component';
import { QrcodeFormComponent } from './pages/qrcode/qrcode-form/qrcode-form.component';
import { QrcodeAnalyticsComponent } from './pages/qrcode/qrcode-analytics/qrcode-analytics.component';
import { QrcodeAnalyticsDetailComponent } from './pages/qrcode/qrcode-analytics/qrcode-analytics-detail/qrcode-analytics-detail.component';
import { ErrorComponent } from './pages/error/error.component';
import { PermissionsGuard } from './services/permissions/permissions.guard';
import { SmartdataComponent } from './pages/smartdata/smartdata.component';
import { SmartdataDetailComponent } from './pages/smartdata/smartdata-detail/smartdata-detail.component';
import { TesteComponent } from './pages/teste/teste.component';
import { AnalyticCategoriesComponent } from './pages/analytic-categories/analytic-categories.component';
import { AnalyticCategoriesFormComponent } from './pages/analytic-categories/analytic-categories-form/analytic-categories-form.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    title: 'Álamo Gestor'
  },
  {
    path: 'demands',
    component: DemandsComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'demands/filter/:status',
    component: DemandsComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'demands/new',
    component: DemandsFormComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'demands/edit/:id',
    component: DemandsFormComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'demands/edit/:id/:hash',
    component: DemandsFormComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'part-types',
    component: PartTypesComponent,
    canActivate: [AuthGuardService],
    title: 'Tipos de Peças'
  },
  {
    path: 'part-types/new',
    component: PartTypesFormComponent,
    canActivate: [AuthGuardService],
    title: 'Tipos de Peças'
  },
  {
    path: 'part-types/edit/:id',
    component: PartTypesFormComponent,
    canActivate: [AuthGuardService],
    title: 'Tipos de Peças'
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'categories/filter/:status',
    component: CategoriesComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'categories/new',
    component: CategoriesFormComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'categories/edit/:id',
    component: CategoriesFormComponent,
    canActivate: [AuthGuardService],
    title: 'Demandas'
  },
  {
    path: 'managers',
    component: ManagersComponent,
    canActivate: [AuthGuardService],
    title: 'Gestores'
  },
  {
    path: 'managers/new',
    component: ManagersFormComponent,
    canActivate: [AuthGuardService],
    title: 'Gestores'
  },
  {
    path: 'managers/edit/:id',
    component: ManagersFormComponent,
    canActivate: [AuthGuardService],
    title: 'Gestores'
  },
  {
    path: 'teste',
    component: TesteComponent,
    canActivate: [AuthGuardService],
    title: 'Indicadores'
  },
  {
    path: 'analytics/categories/new',
    component: AnalyticCategoriesFormComponent,
    canActivate: [AuthGuardService],
    title: 'Indicadores'
  },
  {
    path: 'analytics/categories/edit/:id',
    component: AnalyticCategoriesFormComponent,
    canActivate: [AuthGuardService],
    title: 'Indicadores'
  },
  {
    path: 'analytics/categories',
    component: AnalyticCategoriesComponent,
    canActivate: [AuthGuardService],
    title: 'Indicadores'
  },
  {
    path: 'analytics/demands',
    component: AnalyticsComponent,
    canActivate: [AuthGuardService],
    title: 'Indicadores'
  },
  {
    path: 'analytics/smartmail',
    component: SmartmailAnalyticsComponent,
    canActivate: [AuthGuardService],
    title: 'Smartmail'
  },
  {
    path: 'analytics/smartmail/detail/:smartmail_id',
    component: SmartmailAnalyticsDetailComponent,
    canActivate: [AuthGuardService],
    title: 'Smartmail'
  },
  {
    path: 'analytics/smartcode',
    component: QrcodeAnalyticsComponent,
    canActivate: [AuthGuardService],
    title: 'Smartcode'
  },
  {
    path: 'analytics/smartdata',
    component: SmartdataComponent,
    canActivate: [AuthGuardService],
    title: 'Smartdata'
  },
  {
    path: 'analytics/smartdata/new',
    component: SmartdataDetailComponent,
    canActivate: [AuthGuardService],
    title: 'Smartdata'
  },
  {
    path: 'analytics/smartdata/edit/:id',
    component: SmartdataDetailComponent,
    canActivate: [AuthGuardService],
    title: 'Smartdata'
  },
  {
    path: 'analytics/smartcode/detail/:link_id',
    component: QrcodeAnalyticsDetailComponent,
    canActivate: [AuthGuardService],
    title: 'Smartcode'
  },
  {
    path: 'analytics/dashtree',
    component: DashtreeComponent,
    canActivate: [AuthGuardService],
    title: 'Dashtree'
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuardService, PermissionsGuard],
    title: 'Usuários'
  },
  {
    path: 'users/new',
    component: UsersFormComponent,
    canActivate: [AuthGuardService, PermissionsGuard],
    title: 'Usuários'
  },
  {
    path: 'users/edit/:id',
    component: UsersFormComponent,
    canActivate: [AuthGuardService, PermissionsGuard],
    title: 'Usuários'
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [AuthGuardService],
    title: 'Clientes'
  },
  {
    path: 'clients/new',
    component: ClientsFormComponent,
    canActivate: [AuthGuardService],
    title: 'Clientes'
  },
  {
    path: 'clients/edit/:id',
    component: ClientsFormComponent,
    canActivate: [AuthGuardService],
    title: 'Clientes'
  },
  {
    path: 'kanban',
    component: KabanComponent,
    canActivate: [AuthGuardService],
    title: 'Kanban'
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    title: 'Dashboard'
  },
  {
    path: 'carrefour/solicitations',
    component: SolicitationFormComponent,
    title: 'Solicitação'
  },
  {
    path: 'solicitations',
    component: SolicitationComponent,
    canActivate: [AuthGuardService],
    title: 'Solicitações'
  },
  {
    path: 'status',
    component: StatusComponent,
    canActivate: [AuthGuardService, PermissionsGuard],
    title: 'Status'
  },
  {
    path: 'status/new',
    component: StatusFormComponent,
    canActivate: [AuthGuardService, PermissionsGuard],
    title: 'Status'
  },
  {
    path: 'status/edit/:id',
    component: StatusFormComponent,
    canActivate: [AuthGuardService, PermissionsGuard],
    title: 'Status'
  },
  {
    path: 'smartmail',
    component: SmartmailComponent,
    canActivate: [AuthGuardService],
    data: { permission: 'smartmail' },
    title: 'Smartmail'
  },
  {
    path: 'smartmail/new',
    component: SmartmailEditorComponent,
    canActivate: [AuthGuardService],
    data: { permission: 'smartmail' },
    title: 'Smartmail editor'
  },
  {
    path: 'smartmail/edit/:id',
    component: SmartmailEditorComponent,
    canActivate: [AuthGuardService],
    data: { permission: 'smartmail' },
    title: 'Smartmail editor'
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuardService],
    title: 'Calendário'
  },
  {
    path: 'qrcode',
    component: QrcodeComponent,
    canActivate: [AuthGuardService],
    data: { permission: 'smartcode' },
    title: 'QR Code'
  },
  {
    path: 'qrcode/new',
    component: QrcodeFormComponent,
    canActivate: [AuthGuardService],
    data: { permission: 'smartcode' },
    title: 'QR Code'
  },
  {
    path: 'qrcode/edit/:id',
    component: QrcodeFormComponent,
    canActivate: [AuthGuardService],
    data: { permission: 'smartcode' },
    title: 'QR Code'
  },
  {
    path: 'error',
    component: ErrorComponent,
    title: 'Error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
