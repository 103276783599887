import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class DemandsService {

  constructor(
    private http: HttpClient
  ) { }

  get(filter: any = null) {
    return this.http.post(`${URL_API}/demands/filter`, filter)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/demands/${id}`)
  }

  getText(id: number) {
    return this.http.get(`${URL_API}/demands/text/${id}`)
  }

  updateStatus(data: any) {
    return this.http.post(`${URL_API}/demands/status`, data)
  }

  save(data: FormData) {
    return this.http.post(`${URL_API}/demands`, data)
  }

  saveSolicitation(data: FormData) {
    return this.http.post(`${URL_API}/demands/solicitations`, data)
  }

  update(data: FormData, id: number) {
    return this.http.post(`${URL_API}/demands/${id}`, data)
  }

  delete(id: number) {
    return this.http.delete(`${URL_API}/demands/${id}`)
  }

}
