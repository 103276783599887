<app-page-default title="Formatos">
  <app-box-status></app-box-status>
  <app-default-table
    pageTitle="FORMATOS PEÇAS"
    tableTitle="Formatos"
    urlNewItem="/part-types/new"
    textNewItem="Novo formato"
    editRouter="/part-types/edit"
    [record]="types"
    [skeleton]="skeleton"
    (eventSearch)="setSearch($event)"
    (eventDelete)="delete($event)"
    (eventOrder)="setOrder($event)"
    (eventPage)="setPage($event)">
  </app-default-table>
</app-page-default>