<app-page-default>
    <app-box-status></app-box-status>
    <app-demand-table [limit]="4"></app-demand-table>
    <div class="row mt-4" *ngIf="metrics.length > 0">
        <div class="col-lg-6">
            <app-box-pie-chart [skeleton]="skeleton" [title]="metrics[0].name" [data]="metrics[0].percents"></app-box-pie-chart>
        </div>
        <div class="col-lg-6">
            <app-box-bar-chart [skeleton]="skeleton" [title]="metrics[0].name" [data]="metrics[0].percents"></app-box-bar-chart>
        </div>
    </div>
</app-page-default>