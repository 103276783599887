import { Component, OnInit } from '@angular/core';
import { MetricsService } from 'src/app/services/metrics.service';

@Component({
  selector: 'app-dashtree',
  templateUrl: './dashtree.component.html',
  styleUrls: ['./dashtree.component.scss']
})
export class DashtreeComponent implements OnInit {

  dashtree = ''
  skeleton = true

  constructor(private service: MetricsService) { }

  ngOnInit(): void {
    this.service.getDashTree().subscribe({
      next: (result: any) => {
        this.dashtree = result?.source
        this.skeleton = false
      },
      error: (error) => {
        console.log(error)
        this.skeleton = false
      }
    })
  }

}
