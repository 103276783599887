import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PartsTypesService } from 'src/app/services/parts-types.service';
import { PartsService } from 'src/app/services/parts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-part-types-form',
  templateUrl: './part-types-form.component.html',
  styleUrls: ['./part-types-form.component.scss']
})
export class PartTypesFormComponent implements OnInit {

  skeleton = false
  loading = false

  typeForm: any

  constructor(
    private formBuilder: FormBuilder,
    private service: PartsTypesService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) this.getById(id)
    })

    this.typeForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'id': ['']
    })

  }

  getById(id: any) {
    this.skeleton = true
    this.service.getById(id).subscribe({
      next: (result: any) => {
        this.typeForm.patchValue(result)
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  save(data: any) {
    this.loading = true
    this.service.save(data.value).subscribe({
      next: (result: any) => {
        this.loading = false

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Formato salvo com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.router.navigateByUrl(`/part-types`)
      },
      error: (error) => {
        console.log(error)
        this.loading = false
      }
    })
  }

}
