import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrackingQrcodeService } from 'src/app/services/tracking-qrcode.service';

@Component({
  selector: 'app-qrcode-analytics-detail',
  templateUrl: './qrcode-analytics-detail.component.html',
  styleUrls: ['./qrcode-analytics-detail.component.scss']
})
export class QrcodeAnalyticsDetailComponent implements OnInit {

  skeleton = true

  public chartOptions: any
  public chartOptionsPercent: any
  public chartOptionsArea: any

  labels: Array<string> = []
  series: Array<number> = []

  tracking: any = []
  links: any = []
  views: any = []
  time: any = []

  constructor(
    private service: TrackingQrcodeService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let link_id = params.get('link_id')
      this.getTracking(link_id)
      this.getTimeAccess(link_id)
    })
  }

  getTimeAccess(link_id: any) {
    this.service.getAccessTimeByLinkId(link_id).subscribe({
      next: (result: any) => {
        result.forEach((e: any) => {
          console.log(e)
          this.time.push(`${e.date}h`)
          this.views.push(e.view)
        });
      },
      error: (err) => console.log(err)
    })
  }

  getTracking(link_id: any) {
    this.service.getByLinkId(link_id).subscribe({
      next: (result: any) => {
        this.tracking = result
        this.chartOptions = {
          series: [
            {
              name: "Acessos",
              type: "column",
              data: [this.tracking.access]
            }
          ],
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          chart: {
            height: 400,
            type: "bar",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false,
            },
            animations: {
              enabled: true
            },
          },
          theme: {
            palette: 'palette4' // upto palette10
          },
          dataLabels: {
            enabled: true,
            formatter: function (val:any) {
              return val?.toFixed(0);
            }
          },
          legend: {
            show: true,
          },
          xaxis: {
            categories: [result.name],
            labels: {
              show: false,
            }
          },
          yaxis: {
            labels: {
              show: true,
              formatter: function (val:any) {
                return val?.toFixed(0);
              }
            }
          },
        };

        this.chartOptionsArea = {
          series: [
            {
              name: "Acessos",
              type: "column",
              data: this.views
            },
          ],
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          chart: {
            height: 400,
            type: "bar",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false,
            },
            animations: {
              enabled: true
            },
          },
          theme: {
            palette: 'palette4' // upto palette10
          },
          dataLabels: {
            enabled: true
          },
          legend: {
            show: true,
          },
          xaxis: {
            categories: this.time,
            labels: {
              show: true
            }
          },
          yaxis: {
            labels: {
              show: true,
              formatter: function (val:any) {
                return val?.toFixed(0);
              }
            }
          },
        };
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

}
