import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { URL_API } from 'src/app/services/api';
import { LinksService } from 'src/app/services/links.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { SlugifyPipe } from 'src/app/pipe/slugify';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-qrcode-form',
  templateUrl: './qrcode-form.component.html',
  styleUrls: ['./qrcode-form.component.scss']
})
export class QrcodeFormComponent implements OnInit {

  @ViewChild("qrcode", {static : true}) qrcode: any

  options: AnimationOptions = {
    path: '/assets/argon-dashboard-master/assets/animation/animation_llcrqi5u.json'
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  skeleton = false
  linkForm: any
  name: any
  finalUrl: any
  loading = false

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;

  downloadLink = ''
  nameDownloadLink = ''

  constructor(
    private service: LinksService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private slug: SlugifyPipe,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) this.getById(id)
    })

    this.linkForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'link': ['', Validators.required],
      'id':   ['']
    })
  }

  getById(id: any) {
    this.service.getById(id).subscribe({
      next: (result: any) => {
        this.linkForm.patchValue(result)
        this.finalUrl = `${URL_API}/l/${result.hashid}`
        this.generateImgDownload()
      },
      error: (err) => console.log(err)
    })
  }

  save(data: any) {
    this.loading = true
    setTimeout(() => {      
      this.service.save(data.value).subscribe({
        next: (result: any) => {
          console.log(result)
          this.loading = false
  
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'QR Code salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })
  
          this.router.navigateByUrl(`/qrcode/edit/${result.id}`)
          this.generateImgDownload()
        },
        error: (err) => console.log(err)
      })
    }, 1500);
  }

  copyUrl() {
    this.clipboard.copy(this.finalUrl)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      text: 'Url copiada com sucesso',
      showConfirmButton: false,
      timer: 1500
    })
  }

  generateImgDownload() {
    setTimeout(() => {
      let y: any = document.getElementsByClassName("qrcode")
      this.downloadLink = y[0].querySelector("img")["src"]
      this.nameDownloadLink = `${this.slug.transform(this.linkForm.controls['name'].value)}-qr-code` 
    }, 300);
  }
}
