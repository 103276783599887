<div class="row destaque-status">
    <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <ngx-skeleton-loader 
            *ngIf="skeleton"
            count="1" 
            appearance="line" 
            [theme]="{height: '75px', marginBottom: '10px', borderRadius: '1rem'}">
        </ngx-skeleton-loader>
        <div class="card empty pointer" *ngIf="!statusHighlight[0] && !skeleton" data-bs-toggle="modal" data-bs-target="#modalId">
            <div class="card-body p-2 px-3">
               <p>Selecione um status para exibir</p>
            </div>
        </div>
        <div class="card pointer" *ngIf="statusHighlight[0] && !skeleton">
            <button class="btn btn-remove" (click)="setHighlight(statusHighlight[0])"><i class="fas fa-times"></i></button>
            <div class="card-body p-2 px-3" [routerLink]="'/demands/filter/' + statusHighlight[0].id">
                <div class="row">
                    <div class="col-8">
                        <div class="numbers">
                            <p class="text-sm mb-0 text-uppercase font-weight-bold">{{ statusHighlight[0].name }}</p>
                            <h5 class="font-weight-bolder">{{ statusHighlight[0].demands.length }}</h5>
                        </div>
                    </div>
                    <div class="col-4 text-end">
                        <div class="icon icon-shape shadow-success text-center rounded-circle" [style.background]="statusHighlight[0].color">
                            <i class="text-lg opacity-10" [class]="statusHighlight[0].icon" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <ngx-skeleton-loader 
            *ngIf="skeleton"
            count="1" 
            appearance="line" 
            [theme]="{height: '75px', marginBottom: '10px', borderRadius: '1rem'}">
        </ngx-skeleton-loader>
        <div class="card empty pointer" *ngIf="!statusHighlight[1] && !skeleton" data-bs-toggle="modal" data-bs-target="#modalId">
            <div class="card-body p-2 px-3">
               <p>Selecione um status para exibir</p>
            </div>
        </div>
        <div class="card pointer" *ngIf="statusHighlight[1] && !skeleton">
            <button class="btn btn-remove" (click)="setHighlight(statusHighlight[1])"><i class="fas fa-times"></i></button>
            <div class="card-body p-2 px-3" [routerLink]="'/demands/filter/' + statusHighlight[1].id">
                <div class="row">
                    <div class="col-8">
                        <div class="numbers">
                            <p class="text-sm mb-0 text-uppercase font-weight-bold">{{ statusHighlight[1].name }}</p>
                            <h5 class="font-weight-bolder">{{ statusHighlight[1].demands.length }}</h5>
                        </div>
                    </div>
                    <div class="col-4 text-end">
                        <div class="icon icon-shape shadow-success text-center rounded-circle" [style.background]="statusHighlight[1].color">
                            <i class="text-lg opacity-10" [class]="statusHighlight[1].icon" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
        <ngx-skeleton-loader 
            *ngIf="skeleton"
            count="1" 
            appearance="line" 
            [theme]="{height: '75px', marginBottom: '10px', borderRadius: '1rem'}">
        </ngx-skeleton-loader>
        <div class="card empty pointer" *ngIf="!statusHighlight[2] && !skeleton" data-bs-toggle="modal" data-bs-target="#modalId">
            <div class="card-body p-2 px-3">
               <p>Selecione um status para exibir</p>
            </div>
        </div>
        <div class="card pointer" *ngIf="statusHighlight[2] && !skeleton">
            <button class="btn btn-remove" (click)="setHighlight(statusHighlight[2])"><i class="fas fa-times"></i></button>
            <div class="card-body p-2 px-3" [routerLink]="'/demands/filter/' + statusHighlight[2].id">
                <div class="row">
                    <div class="col-8">
                        <div class="numbers">
                            <p class="text-sm mb-0 text-uppercase font-weight-bold">{{ statusHighlight[2].name }}</p>
                            <h5 class="font-weight-bolder">{{ statusHighlight[2].demands.length }}</h5>
                        </div>
                    </div>
                    <div class="col-4 text-end">
                        <div class="icon icon-shape shadow-success text-center rounded-circle" [style.background]="statusHighlight[2].color">
                            <i class="text-lg opacity-10" [class]="statusHighlight[2].icon" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Body -->
<div class="modal fade" id="modalId" tabindex="-1" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="modalTitleId">Selecione o status</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body bg-gradient-primary">
                <div class="row destaque-status">
                    <div class="col-12" *ngIf="status.length == 0">
                        <p class="text-light m-0 py-3">Nenhum item disponível</p>
                    </div>
                    <div class="col-lg-6" *ngFor="let s of status">
                        <div class="card pointer my-2" [class.actived]="s.highlight" (click)="setHighlight(s)">
                            <div class="card-body p-2 px-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-uppercase font-weight-bold">{{ s.name }}</p>
                                            <h5 class="font-weight-bolder">{{ s.demands.length }}</h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div class="icon icon-shape shadow-success text-center rounded-circle" [style.background]="s.color">
                                            <i class="text-lg opacity-10" [class]="s.icon" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mb-1" data-bs-dismiss="modal">Fechar</button>
            </div>
        </div>
    </div>
</div>
