import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrackingService } from 'src/app/services/tracking.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-smartmail-analytics-detail',
  templateUrl: './smartmail-analytics-detail.component.html',
  styleUrls: ['./smartmail-analytics-detail.component.scss']
})
export class SmartmailAnalyticsDetailComponent implements OnInit {

  public chartOptions: any
  public chartOptionsPercent: any
  public chartOptionsArea: any

  labels: Array<string> = []
  series: Array<number> = []

  skeleton = true

  tracking: any = []
  links: any = []
  views: any = []
  time: any = []

  smartmail_id: any = 0

  exportLoading = false

  constructor(
    private service: TrackingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      setTimeout(() => {
        this.smartmail_id = params.get('smartmail_id')
        this.getTracking(this.smartmail_id)
        this.getLink(this.smartmail_id)
        this.getTimeAccess(this.smartmail_id)
      }, 1000);
    })
  }

  getLink(smartmail_id: any) {
    this.service.getLinksBySmartMailId(smartmail_id).subscribe({
      next: (result) => {
        this.links = result
        console.log(result)
      },
      error: (err) => console.log(err)
    })
  }

  getTimeAccess(smartmail_id: any) {
    this.service.getAccessTimeBySmartMailId(smartmail_id).subscribe({
      next: (result: any) => {
        result.forEach((e: any) => {
          console.log(e)
          this.time.push(`${e.date}h`)
          this.views.push(e.view)
        });
      },
      error: (err) => console.log(err)
    })
  }

  getTracking(smartmail_id: any) {
    this.service.getBySmartMailId(smartmail_id).subscribe({
      next: (result: any) => {
        this.tracking = result
        this.chartOptions = {
          series: [
            {
              name: "Acessos",
              type: "column",
              data: [this.tracking.access]
            },
            {
              name: "Cliques",
              type: "bar",
              data: [this.tracking.clicks]
            },
            // {
            //   name: "Conversão (%)",
            //   type: "bar",
            //   data: [this.tracking.ctr]
            // },
          ],
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          chart: {
            height: 400,
            type: "bar",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false,
            },
            animations: {
              enabled: true
            },
          },
          theme: {
            palette: 'palette1' // upto palette10
          },
          dataLabels: {
            enabled: true,
            formatter: function (val:any) {
              return val?.toFixed(0);
            }
          },
          legend: {
            show: true,
          },
          xaxis: {
            categories: [result.name],
            labels: {
              show: false,
            }
          },
          yaxis: {
            labels: {
              show: true,
              formatter: function (val:any) {
                return val?.toFixed(0);
              }
            }
          }
        };

        this.chartOptionsPercent = {
          series: [result.ctr],
          chart: {
            height: 437,
            type: "radialBar",
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            radialBar: {
              // startAngle: -135,
              // endAngle: 225,
              hollow: {
                margin: 0,
                size: "40%",
                background: "#fff",
                image: undefined,
                position: "front"
              },
              track: {
                background: "#f1f1f1",
                strokeWidth: "100%",
                margin: 40, // margin is in pixels}
              },
    
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "14px"
                },
                formatter: function (val:any) {
                  return val?.toFixed(0);
                },
                value: {
                  formatter: function(val: any) {
                    return val?.toFixed(2) + '%';
                  },
                  color: "#111",
                  fontSize: "40px",
                  fontWeight: "bold",
                  show: true
                }
              }
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.9,
              gradientToColors: ["#ABE5A1"],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: "round",
            curve: "smooth",
          },
          labels: ["Aberturas x Cliques"]
        };


        this.chartOptionsArea = {
          series: [
            {
              name: "Acessos",
              type: "line",
              data: this.views
            },
          ],
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          chart: {
            height: 400,
            type: "line",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false,
            },
            animations: {
              enabled: true
            },
          },
          theme: {
            palette: 'palette4' // upto palette10
          },
          dataLabels: {
            enabled: true
          },
          legend: {
            show: true,
          },
          xaxis: {
            categories: this.time,
            labels: {
              show: true,
              formatter: function (val:any) {
                return val?.toFixed(0) + 'h';
              }
            }
          },
          stroke: {
            lineCap: "round",
            curve: "smooth",
          },
          yaxis: {
            labels: {
              show: true,
              formatter: function (val:any) {
                return val?.toFixed(0);
              }
            }
          },
        };
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  export() {
    this.exportLoading = true
    this.service.export(this.smartmail_id).subscribe({
      next: (result: any) => {
        this.exportLoading = false
        window.open(result.file, 'blank')
      },
      error: (err) => console.log(err)
    })
  }

}
