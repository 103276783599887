import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { URL_API } from '../api';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token: any
  private redirectUrl: string = '/';

  userLoggedIn = new EventEmitter()
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' })

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) {
    this.token = localStorage.getItem('access_token');
  }

  login(credentials: any) {
    const url = `${URL_API}/auth/login`;
    return this.http.post(url, JSON.stringify(credentials), { headers: this.headers }).pipe(
      map((response: any) => {

        const token = response.token;
        const user = response.user;
        const roles = response.roles;

        if (token && user) {
          this.token = token;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('access_token', token);
          localStorage.setItem('roles', JSON.stringify(roles))
          this.userLoggedIn.emit();
          return true;
        }
        return false;
      }));
  }

  getToken() {
    return this.token;
  }

  loggedIn() {
    if (this.getToken()) {
      return !this.jwtHelper.isTokenExpired();
    }
    return false;
  }

  logout() {
    this.token = null;
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
  }

  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  public getRedirectUrl(): string {
    return this.redirectUrl;
  }

}
