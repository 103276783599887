<app-page-default>
    <app-box-status></app-box-status>
    <div class="row" *ngIf="skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
                <ngx-skeleton-loader 
                    *ngIf="skeleton"
                    count="5" 
                    appearance="line" 
                    [theme]="{height: '40px', marginBottom: '10px', borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="!skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
                <h6 class="mb-0">Cliente</h6>
                <p class="text-sm mb-0">Gerencie os dados do cliente aqui</p>
                <form [formGroup]="clientForm">
                    <div class="row align-items-end">
                        <div class="col-lg-6">
                            <label class="form-label mt-4">Nome</label>
                            <input type="text" class="form-control" formControlName="name">
                        </div>
                        <div class="col-lg-6">
                            <div class="form-check mb-2 form-switch">
                                <input class="form-check-input" type="checkbox" formControlName="active" id="active">
                                <label class="form-check-label" for="active">Ativo</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-lg-4">
            <button type="button" 
                    name="button" 
                    [disabled]="loading" 
                    routerLink="/clients" 
                    class="btn btn-light m-0">
                        Voltar
            </button>
        </div>
        <div class="col-lg-8 col-12 mx-auto">
            <div class="d-flex justify-content-end">
                <button type="button" 
                        name="button" 
                        [disabled]="loading" 
                        routerLink="/clients" 
                        class="btn btn-light m-0">
                            Cancelar
                </button>
                <button type="button" 
                        name="button" 
                        [disabled]="loading"
                        (click)="save(clientForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Salvando' : 'Salvar cliente' }}
                </button>
            </div>
        </div>
    </div>
</app-page-default>