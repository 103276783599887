import { Component, OnInit } from '@angular/core';
import { AnalyticCategoriesService } from 'src/app/services/analytic-categories.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-analytic-categories',
  templateUrl: './analytic-categories.component.html',
  styleUrls: ['./analytic-categories.component.scss']
})
export class AnalyticCategoriesComponent implements OnInit {

  categories = []
  skeleton = true
  query: any
  order = 'desc'
  page = 1

  constructor(
    private service: AnalyticCategoriesService
  ) { }

  ngOnInit(): void {
    this.get()
  }

  get() {
    this.service.get({
      limit:  null, 
      query:  this.query,
      order:  this.order,
      page:   this.page 
    }).subscribe({
      next: (result:any) => {
        this.skeleton   = false
        this.categories = result;
        console.log(this.categories)
      },
      error: (err) => console.log(err)
    })
  }

  delete(item: any) {
    // Swal.fire({
    //   icon: 'warning',
    //   text: `Deseja remover a categoria ${item.name}?`,
    //   showCancelButton: true,
    //   confirmButtonText: 'Excluir',
    //   cancelButtonText: 'Cancelar',
    //   confirmButtonColor: '#f5365c',
    // }).then(result => {
    //   if (result.value) {
    //     if (item.id) {
    //       this.service.delete(item.id).subscribe({
    //         next: () => this.get(),
    //         error: (err) => console.log(err)
    //       })
    //     } 
    //   }
    // })
  }

  setOrder(order: any) {
    this.order = order
    this.get()
  }

  setPage(p: number) {
    this.page = p
    this.get()
  }

  setSearch(query: string) {
    this.query = query
    this.get()
  }
 

}
