<app-page-default title="Calendário">
    <app-box-status></app-box-status>
    <div class="card mt-4">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-6">
                    <div class="btn-group">
                        <div
                          class="btn btn-sm btn-outline-primary"
                          (click)="setView(CalendarView.Month)"
                          [class.active]="view === CalendarView.Month">
                          Meses
                        </div>
                        <div
                          class="btn btn-sm btn-outline-primary"
                          (click)="setView(CalendarView.Week)"
                          [class.active]="view === CalendarView.Week">
                          Semanas
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-flex justify-content-end">
                    <div class="btn-group">
                        <div
                          class="btn btn-sm btn-outline-primary"
                          mwlCalendarPreviousView
                          [view]="view"
                          [(viewDate)]="viewDate"
                          (viewDateChange)="closeOpenMonthViewDay()">
                          Anterior
                        </div>
                        <div
                          class="btn btn-sm btn-outline-primary"
                          mwlCalendarToday
                          [(viewDate)]="viewDate">
                          Hoje
                        </div>
                        <div
                          class="btn btn-sm btn-outline-primary"
                          mwlCalendarNextView
                          [view]="view"
                          [(viewDate)]="viewDate"
                          (viewDateChange)="closeOpenMonthViewDay()">
                          Próximo
                        </div>
                    </div>
                </div>
            </div>
            <div [ngSwitch]="view" class="mt-2">
                <mwl-calendar-month-view
                    *ngSwitchCase="CalendarView.Month"
                    [viewDate]="viewDate"
                    [activeDayIsOpen]="activeDayIsOpen"
                    (eventClicked)="eventClicked($event, content)"
                    (dayClicked)="dayClicked($event.day)"
                    [events]="events">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                    *ngSwitchCase="CalendarView.Week"
                    [viewDate]="viewDate"
                    (eventClicked)="eventClicked($event, content)"
                    [events]="events">
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                    *ngSwitchCase="CalendarView.Day"
                    [viewDate]="viewDate"
                    (eventClicked)="eventClicked($event, content)"
                    [events]="events">
                </mwl-calendar-day-view>
            </div>
        </div>
    </div>

</app-page-default>

<ng-template #content let-modal>
    <div class="modal-header">
        <h6 class="modal-title mb-0">Demanda</h6>
        <button type="button" class="btn btn-sm btn-primary mb-0" aria-label="Close" (click)="modal.dismiss('Cross click')">x</button>
    </div>
    <div class="modal-body">
        <app-demands-form #demand [demand_id]="demand_id" (cancelModal)="modal.dismiss('Cross click')" (updateCalendar)="getDemands()" [modal]="true"></app-demands-form>
    </div>
</ng-template>