import { Component, OnInit } from '@angular/core';
import { DemandsService } from 'src/app/services/demands.service';

@Component({
  selector: 'app-demands',
  templateUrl: './demands.component.html',
  styleUrls: ['./demands.component.scss']
})
export class DemandsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
