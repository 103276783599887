import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from './api';

@Injectable({
  providedIn: 'root'
})
export class SmartmailService {

  constructor(
    private http: HttpClient
  ) { }

  get(filter: any = null) {
    return this.http.post(`${URL_API}/smartmail/filter`, filter)
  }

  getById(id: number) {
    return this.http.get(`${URL_API}/smartmail/${id}`)
  }

  save(data: FormData) {  
    return this.http.post(`${URL_API}/smartmail`, data)
  }

  delete(id: number) {
    return this.http.delete(`${URL_API}/smartmail/${id}`)
  }

}
