<app-page-default title="Smartdata">
    <app-box-status></app-box-status>
    <app-default-table
        pageTitle="SMARTDATA"
        tableTitle="Análises"
        urlNewItem="/analytics/smartdata/new"
        textNewItem="Nova análise"
        editRouter="/analytics/smartdata/edit"
        [record]="smartdatas"
        [skeleton]="skeleton" 
        (eventSearch)="setSearch($event)"
        (eventDelete)="delete($event)"
        (eventOrder)="setOrder($event)"
        (eventPage)="setPage($event)">
    </app-default-table>
</app-page-default>