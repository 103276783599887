<app-page-default title="Dashtree">
    <app-box-status></app-box-status>
    <div class="card mt-4" >
        <div class="card-body">
            <iframe *ngIf="dashtree" title="DashTree" width="100%" height="700" [src]="dashtree | safe" frameborder="0" allowFullScreen="true"></iframe>
            <p *ngIf="!dashtree && !skeleton" class="mb-0 text-center py-5">Dashtree não disponível no momento</p>
            <ngx-skeleton-loader 
                *ngIf="skeleton"
                count="1" 
                appearance="line" 
                [theme]="{height: '200px', marginBottom: '10px', borderRadius: '1rem'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</app-page-default>