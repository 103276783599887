<app-page-default title="Clientes">
    <app-box-status></app-box-status>
    <app-default-table
      pageTitle="CLIENTES"
      tableTitle="Clientes"
      urlNewItem="/clients/new"
      textNewItem="Novo cliente"
      editRouter="/clients/edit"
      [record]="clients"
      [skeleton]="skeleton"
      (eventSearch)="setSearch($event)"
      (eventDelete)="delete($event)"
      (eventOrder)="setOrder($event)"
      (eventPage)="setPage($event)">
    </app-default-table>
  </app-page-default>