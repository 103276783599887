import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { isSameDay } from 'date-fns';
import { DemandsService } from 'src/app/services/demands.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  viewDate: Date = new Date();
  view = CalendarView.Month
  CalendarView = CalendarView;
  activeDayIsOpen: boolean = false;

  demand_id: any = 0

  events: any[] = [] 

  constructor(
    private service: DemandsService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getDemands()
  }

  getDemands() {
    this.events = []
    this.service.get({
      limit:  20, 
      page:   1,
      order: 'desc'
    }).subscribe({
      next: (result: any) => {
        result?.data.forEach((e: any) => {
          this.events = [
            ...this.events,
            {
              id: e.id,
              color: { 
                ...
                {
                  primary: e.status?.color,
                  secondary: `${e.status?.color}B3`,
                } 
              },
              start: new Date(e.date),
              end: new Date(e.end),
              title: e.name,
              allDay: true,
            }
          ]
        });
      },
      error: (err) => console.log(err)
    })
  }

  eventClicked({ event }: { event: CalendarEvent }, content: any): void {
    this.demand_id = event.id
    this.modalService.open(content, { size: 'xl' });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  
  dayClicked({date, events}: { date: Date; events: CalendarEvent<{ film: any }>[]}): void {
    if(isSameDay(this.viewDate, date) && this.activeDayIsOpen === true || events.length == 0) {
      this.activeDayIsOpen = false
    } else {
      this.activeDayIsOpen = true
      this.viewDate = date
    }
  }

}
