
<div class="card z-index-2 h-100">
    <div class="card-header pb-0 pt-3 bg-transparent">
        <h6 class="text-capitalize mb-0">{{ title }}</h6>
        <p class="text-xs text-secondary mb-0">Quantidade de demandas</p>
    </div>
    <div class="card-body p-3">
        <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [theme]="chartOptions.theme" 
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [legend]="chartOptions.legend"
            [grid]="chartOptions.grid"
            [noData]="chartOptions.noData"
            [xaxis]="chartOptions.xaxis"
      ></apx-chart>
    </div>
</div>